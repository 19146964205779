import { useLoader, useFrame } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useScroll } from "../ScrollControls";
import * as THREE from "three";
import { LoadingContext } from "../../context/loading.context";

const DroppingSeed = ({ scale, position, setIsSeedInDestination }) => {
  const { setLoading } = useContext(LoadingContext);

  const { scroll } = useScroll();
  const gltf = useLoader(
    GLTFLoader,
    "/assets/3d-Models/Seed_NEW_NOT_COMPRESSED2.gltf"
  );
  const modelRef = useRef();
  const currentPositionRef = useRef(null);

  useEffect(() => {
    if (gltf.scene) {
      gltf.scene.scale.set(...scale);
      gltf.scene.renderOrder = 1;
      setLoading("section2Seed", true);
    }
  }, [gltf]);

  useEffect(() => {
    currentPositionRef.current = position[1];
  }, []);

  useFrame(() => {
    const currentValue = (scroll.current * 100).toFixed(2);

    if (modelRef.current && currentValue > 54.6) {
      // modelRef.current.rotation.y += 0.005;
    }

    if (currentValue < 40) {
      currentPositionRef.current = position[1];
      modelRef.current.position.set(...position);
    }

    const isInFirstPosition = currentValue < 54.8;
    if (isInFirstPosition) {
      const targetY = -14;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.045
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }

    const isInSecondPosition = currentValue > 54.8 && currentValue <= 55.8;
    if (isInSecondPosition) {
      const targetY = -14.9;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.045
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }

    const isInThirdPosition = currentValue > 55.8 && currentValue <= 56.8;
    if (isInThirdPosition) {
      const targetY = -15.4;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.045
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }

    const isInFourthPosition = currentValue > 56.8 && currentValue <= 57.8;
    if (isInFourthPosition) {
      const targetY = -15.7;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.045
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }

    const isInFifthPosition = currentValue > 57.8 && currentValue <= 58.8;
    if (isInFifthPosition) {
      const targetY = -16;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.045
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }

    const isInSixthPosition = currentValue > 58.8 && currentValue < 59.8;
    if (isInSixthPosition) {
      const targetY = -16.5;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.065
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }

    const isInSeventhPosition = currentValue >= 59.8;
    if (isInSeventhPosition) {
      const targetY = -16.9;
      currentPositionRef.current = THREE.MathUtils.lerp(
        currentPositionRef.current,
        targetY,
        0.065
      );

      modelRef.current.position.set(
        modelRef.current.position.x,
        currentPositionRef.current,
        modelRef.current.position.z
      );
    }
    if (currentPositionRef.current < -16.83) {
      setIsSeedInDestination(true);
    } else {
      setIsSeedInDestination(false);
    }
  });

  return (
    <primitive
      ref={modelRef}
      object={gltf.scene.clone(true)}
      position={position}
    />
  );
};

export default DroppingSeed;
