import React, { useRef, useEffect, useState, useContext } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { LoadingContext } from "../../context/loading.context";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { useScroll } from "../ScrollControls";

const VideoLoader = ({ width, height, position, scale, startTime }) => {
  const meshRef = useRef();
  const textureLoader = new THREE.TextureLoader(); // Initialize TextureLoader
  const [activeTexture, setActiveTexture] = useState(null);
  const { setLoading } = useContext(LoadingContext);
  const { scroll, el } = useScroll();
  const prevScrollRef = useRef();
  var userAgent = navigator.userAgent;

  useEffect(() => {
    // Load textures asynchronously
    const loadTextures = async () => {
      const texture1 = await textureLoader.loadAsync(
        "assets/img/outdoor-loop-new.png"
      );
      const texture2 = await textureLoader.loadAsync(
        "assets/img/greenhouseloop-new.png"
      );
      const texture3 = await textureLoader.loadAsync(
        "assets/img/indoorloop-new.png"
      );

      // Set the active texture based on startTime
      switch (startTime) {
        case 0:
          increaseImageOpacity();
          setActiveTexture(texture1);
          decreaseImageOpacity();
          break;
        case 1:
          setActiveTexture(texture2);
          break;
        case 2:
          setActiveTexture(texture3);
          break;
        default:
          setActiveTexture(texture1);
      }

      setLoading("section3Images", true); // Update loading state
    };

    loadTextures();
  }, [startTime, setLoading]);

  const imageOpacityInterval = useRef(null);
  const [imageOpacity, setImageOpacity] = useState(1);
  const increaseImageOpacity = () => {
    clearInterval(imageOpacityInterval.current);
    imageOpacityInterval.current = null;
    const fadeIn = () => {
      setImageOpacity((prevOpacity) => {
        const newValue = Math.min(prevOpacity + 0.05, 1);
        if (newValue > 0.98) {
          clearInterval(imageOpacityInterval.current);
          imageOpacityInterval.current = null;
        }
        return newValue;
      });
    };

    imageOpacityInterval.current = setInterval(() => {
      fadeIn();
    }, 100);
  };

  const decreaseImageOpacity = () => {
    clearInterval(imageOpacityInterval.current);
    imageOpacityInterval.current = null;
    const fadeOut = () => {
      setImageOpacity((prevOpacity) => {
        const newValue = Math.max(prevOpacity - 0.15, 0);
        if (newValue === 0) {
          clearInterval(imageOpacityInterval.current);
          imageOpacityInterval.current = null;
        }
        return newValue;
      });
    };

    imageOpacityInterval.current = setInterval(() => {
      fadeOut();
    }, 100);
  };

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    // //  section 3 entry
    if (currentScroll >= 70 && currentScroll <= 80.5) {
      scroll.current += 0.14 / 100;
      if (currentScroll > 80 && currentScroll < 80.5)
        setScrollTopToPercentage(80.5, el);
    }

    // //set percentage
    if (currentScroll > 91.6 && currentScroll < 92.2) {
      setScrollTopToPercentage(92, el);
    }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/Android/i)
    ) {
      if (currentScroll > 92 && currentScroll <= 95) {
        // scroll.current -= 0.14 / 100;
      }
    } else {
      // // section 3 exit
      if (currentScroll > 92 && currentScroll <= 98.9) {
        scroll.current -= 0.14 / 100;
      }
    }

    prevScrollRef.current = currentScroll;
  });

  return (
    <>
      {activeTexture && (
        <mesh ref={meshRef} position={position} scale={scale}>
          <planeGeometry args={[width, height, 1, 1]} />
          <meshBasicMaterial map={activeTexture} opacity={imageOpacity} />
        </mesh>
      )}
    </>
  );
};

export default VideoLoader;
