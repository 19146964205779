import { Point, Points } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import { AdditiveBlending, SRGBColorSpace, TextureLoader } from "three";

const particleColors = [
  "#063970",
  "#26004d",
  "#43b5a0",
  "#491d88",
  "#5e5390",
  // "#c4d201",
];

const Particles = ({ size = 5000 }) => {
  const { width, height } = useThree((state) => state.viewport);
  const texture = useLoader(TextureLoader, "/assets/img/circle.png");
  texture.colorSpace = SRGBColorSpace;

  const randomSizeOfPoints = [
    { particlesCount: 2200, pointSize: 0.0248 },
    { particlesCount: 1800, pointSize: 0.0148 },
    { particlesCount: 800, pointSize: 0.0348 },
  ];
  const randomSize = [4.3, 4.4, 4.5, 4.6, 5];

  const pointsRefs = useRef(randomSizeOfPoints.map(() => React.createRef()));

  useFrame(() => {
    pointsRefs.current.forEach((pointsRef) => {
      const pointsGroup = pointsRef.current;
      if (pointsGroup) {
        pointsGroup.children.forEach((point, i) => {
          if (i % 2 === 0) {
            point.position.x += 0.0009; // Move right
          } else {
            point.position.x -= 0.0009; // Move left
          }
        });
      }
    });
  });

  return (
    <>
      {randomSizeOfPoints.map((point1, index) => (
        <Points
          ref={pointsRefs.current[index]}
          limit={2200}
          position={[0, -10, -1.4]}
          renderOrder={-1}
          key={index}
        >
          <pointsMaterial
            size={point1.pointSize} // Adjust the size of the points
            vertexColors
            map={texture}
            alphaTest={0.5} // Adjust the alpha test value
            transparent={true}
            opacity={0.8} // Adjust the opacity of the points
            depthTest={true} // Disable depth testing for transparency
            blending={AdditiveBlending} // Use additive blending for a brighter appearance
          />
          {Array.from({ length: size }).map((_, i) => (
            <Point
              key={i}
              position={[
                (0.5 - Math.random()) * width * 1,
                0.7 * height + Math.random() ** 0.5 * 4 * -1.6,
                randomSize[Math.floor(Math.random()) * 3],
              ]}
              color={
                particleColors[
                  Math.floor(Math.random() * particleColors.length)
                ]
              }
            />
          ))}
        </Points>
      ))}
    </>
  );
};

export default Particles;
