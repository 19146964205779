var newline = /\n/;
var newlineChar = "\n";
var whitespace = /\s/;

module.exports = function (text, opt) {
  var lines = module.exports.lines1(text, opt);
  return lines
    .map(function (line) {
      return text.substring(line.start, line.end);
    })
    .join("\n");
};

module.exports.lines1 = function wordwrap(text, opt) {
  opt = opt || {};

  //zero width results in nothing visible
  if (opt.width === 0 && opt.mode !== "nowrap") return [];

  text = text || "";
  var width = typeof opt.width === "number" ? opt.width : Number.MAX_VALUE;
  var start = Math.max(0, opt.start || 0);
  var end = typeof opt.end === "number" ? opt.end : text.length;
  var mode = opt.mode;

  var measure = opt.measure || monospace;
  if (mode === "pre") return pre(measure, text, start, end, width);
  else return greedy(measure, text, start, end, width, mode);
};

function idxOf(text, chr, start, end) {
  var idx = text.indexOf(chr, start);
  if (idx === -1 || idx > end) return end;
  return idx;
}

function isWhitespace(chr) {
  return whitespace.test(chr);
}

function pre(measure, text, start, end, width) {
  var lines = [];
  var lineStart = start;
  for (var i = start; i < end && i < text.length; i++) {
    var chr = text.charAt(i);
    var isNewline = newline.test(chr);

    //If we've reached a newline, then step down a line
    //Or if we've reached the EOF
    if (isNewline || i === end - 1) {
      var lineEnd = isNewline ? i : i + 1;
      var measured = measure(text, lineStart, lineEnd, width);
      lines.push(measured);

      lineStart = i + 1;
    }
  }
  return lines;
}

function greedy(measure, text, start, end, width, mode) {
  //A greedy word wrapper based on LibGDX algorithm
  //https://github.com/libgdx/libgdx/blob/master/gdx/src/com/badlogic/gdx/graphics/g2d/BitmapFontCache.java
  var lines = [];

  var testWidth = width;
  //if 'nowrap' is specified, we only wrap on newline chars
  if (mode === "nowrap") testWidth = Number.MAX_VALUE;
  while (start < end && start < text.length) {
    //get next newline position
    var newLine = idxOf(text, newlineChar, start, end);

    //determine visible # of glyphs for the available width
    var measured = measure(text, start, newLine, testWidth);

    var lineEnd = start + (measured.end - measured.start);
    var nextStart = lineEnd + newlineChar.length;

    //if we had to cut the line before the next newline...
    if (lineEnd < newLine) {
      const isObjectExist = lines.map((line) => line.end);
      if (isObjectExist.length) {
        lines[isObjectExist.length - 1].end += 1;
      } else {
        var result = measure(text, start, lineEnd, testWidth);
        lines.push(result);
      }
    } else {
      var result = measure(text, start, lineEnd, testWidth);
      lines.push(result);
    }

    start = nextStart;
  }

  return lines;
}

//determines the visible number of glyphs within a given width
function monospace(text, start, end, width) {
  var glyphs = Math.min(width, end - start);
  return {
    start: start,
    end: start + glyphs,
  };
}
