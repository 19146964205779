import React, { createContext, useEffect, useState } from "react";

const defaultValues = {
  enableScroll: true,
  setEnableScroll: () => {},
};
export const ScrollContext = createContext(defaultValues);

export const ScrollContextProvider = ({ children }) => {
  const [enableScroll, setEnableScroll] = useState(true);

  const value = {
    enableScroll,
    setEnableScroll,
  };

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};
