import * as THREE from "three";
import {
  context as fiberContext,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { mergeRefs } from "react-merge-refs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollContext } from "../context/scroll.context";
import { Section8Context } from "../context/section8.context";

const context = React.createContext(null);
export function useScroll() {
  return React.useContext(context);
}
let BottomScroll = false;
let TopScroll = false;
export const ScrollControls = ({
  eps = 0.00001,
  enabled = true,
  infinite,
  vertical,
  pages = 10,
  distance = 1,
  damping = 4,
  children,
}) => {
  const { gl, size, invalidate, events, raycaster } = useThree();
  const el = React.useRef(document.createElement("div")).current;
  const fill = React.useRef(document.createElement("div")).current;
  const fixed = React.useRef(document.createElement("div")).current;
  const { enableScroll } = React.useContext(ScrollContext);
  const target = gl.domElement.parentNode;
  const scroll = React.useRef(0);
  const scrollBuffer = useRef(0);

  const state = React.useMemo(() => {
    return {
      el,
      eps,
      fill,
      fixed,
      vertical,
      damping,
      offset: 0,
      delta: 0,
      scroll,
      pages,
      range(from, distance, margin = 0) {
        const start = from - margin;
        const end = start + distance + margin * 2;
        return this.offset < start
          ? 0
          : this.offset > end
          ? 1
          : (this.offset - start) / (end - start);
      },
      curve(from, distance, margin = 0) {
        return Math.sin(this.range(from, distance, margin) * Math.PI);
      },
      visible(from, distance, margin = 0) {
        const start = from - margin;
        const end = start + distance + margin * 2;
        return this.offset >= start && this.offset <= end;
      },
    };
  }, [eps, damping, vertical, pages]);

  var userAgent = navigator.userAgent;
  const { showContactPage, setShowContactPage } = useContext(Section8Context);

  React.useEffect(() => {
    el.style.position = "absolute";
    el.style.width = "100%";
    el.style.height = "100%";
    el.style[vertical ? "overflowY" : "overflowX"] = "auto";
    el.style[vertical ? "overflowX" : "overflowY"] = "hidden";
    el.style.top = "0px";
    el.style.left = "0px";

    fixed.style.position = "sticky";
    fixed.style.top = "0px";
    fixed.style.left = "0px";
    fixed.style.width = "100%";
    fixed.style.height = "100%";
    fixed.style.overflow = "hidden";
    el.appendChild(fixed);

    fill.style.width = vertical ? "100%" : `${pages * distance * 100}%`;
    fill.style.height = vertical ? `${pages * distance * 100}%` : "100%";
    fill.style.pointerEvents = "none";
    el.appendChild(fill);
    target.appendChild(el);

    el[vertical ? "scrollTop" : "scrollLeft"] = 1;

    const oldTarget =
      typeof events.connected !== "boolean" ? events.connected : gl.domElement;
    requestAnimationFrame(() => events.connect?.(el));
    const oldCompute = raycaster.computeOffsets;
    raycaster.computeOffsets = ({ clientX, clientY }) => ({
      offsetX: clientX - target.offsetLeft,
      offsetY: clientY - target.offsetTop,
    });

    return () => {
      target.removeChild(el);
      raycaster.computeOffsets = oldCompute;
      events.connect?.(oldTarget);
    };
  }, [pages, distance, vertical, el, fill, fixed, target]);

  const scrollToBottom = (scrollDivRef) => {
    if (scrollDivRef?.scrollTo) {
      if (
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPad/i) ||
        userAgent.match(/Android/i)
      ) {
        const containerLength = size[vertical ? "height" : "width"];
        const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
        const scrollThreshold = scrollLength - containerLength;
        scroll.current = state.offset = 0.998;
        // setTimeout(() => {
        scrollDivRef.scrollTo({
          top: 0.998 * scrollThreshold,
          behavior: "instant",
        });
        // }, 0);
      } else {
        scroll.current = state.offset = 0.998;
        const containerLength = size[vertical ? "height" : "width"];
        const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
        const scrollThreshold = scrollLength - containerLength;
        setTimeout(() => {
          scrollDivRef.scrollTo({
            top: 0.97 * scrollThreshold,
            behavior: "instant",
          });
        }, 0);
      }
    }
    // setTimeout(() => {
    //   BottomScroll = false;
    // }, 700);
  };

  const scrollToTop = (scrollDivRef) => {
    if (scrollDivRef?.scrollTo) {
      if (
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPad/i) ||
        userAgent.match(/Android/i)
      ) {
        const containerLength = size[vertical ? "height" : "width"];
        const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
        const scrollThreshold = scrollLength - containerLength;
        scroll.current = state.offset = 0.0022;

        // setTimeout(() => {
        scrollDivRef.scrollTo({
          top: 0.0022 * scrollThreshold,
          behavior: "instant",
        });
        // }, 0);
      } else {
        const containerLength = size[vertical ? "height" : "width"];
        const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
        const scrollThreshold = scrollLength - containerLength;
        scroll.current = state.offset = 0.0021;

        setTimeout(() => {
          scrollDivRef.scrollTo({
            top: 0.0021 * scrollThreshold,
            behavior: "instant",
          });
        }, 0);
      }
    }
    // setTimeout(() => {
    //   TopScroll = false;
    // }, 700);
  };

  const getTimeoutValue = () => {
    const containerLength = size[vertical ? "height" : "width"];
    const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
    const scrollThreshold = scrollLength - containerLength;

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/Android/i)
    ) {
      if (
        Number(state.offset * scrollThreshold + el.offsetHeight) >=
          Number(0.985 * scrollThreshold + el.offsetHeight) ||
        state.offset * scrollThreshold < 0.015 * scrollThreshold
      ) {
        setTimeout(() => {
          scrollBuffer.current = 10000000;
        }, 0);
      } else {
        setTimeout(() => {
          scrollBuffer.current = damping;
        }, 0);
      }
    } else {
      if (
        Number(state.offset * scrollThreshold + el.offsetHeight) >=
          Number(0.999 * scrollThreshold + el.offsetHeight) ||
        state.offset * scrollThreshold < 0.015 * scrollThreshold
      ) {
        scrollBuffer.current = 10000000;
      } else {
        scrollBuffer.current = damping;
      }
    }
  };
  let going1 = false;

  React.useEffect(() => {
    const containerLength = size[vertical ? "height" : "width"];
    const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
    const scrollThreshold = scrollLength - containerLength;

    let current = 0;
    let disableScroll = true;
    let firstRun = true;

    const onScroll = (e) => {
      if (!enabled || !enableScroll || firstRun) {
        e.preventDefault(); // Prevent default scrolling behavior
        return;
      }
      getTimeoutValue();

      invalidate();

      current = el[vertical ? "scrollTop" : "scrollLeft"];

      if (
        Number(state.offset * scrollThreshold) >= 0.008 * scrollThreshold ||
        Number(state.offset * scrollThreshold + el.offsetHeight) <=
          Number(0.992 * scrollThreshold + el.offsetHeight)
      ) {
        scroll.current = current / scrollThreshold;
      }

      if (infinite) {
        if (!disableScroll) {
          const containerLength = size[vertical ? "height" : "width"];
          const scrollLength = el[vertical ? "scrollHeight" : "scrollWidth"];
          const scrollThreshold = scrollLength - containerLength;
          if (
            userAgent.match(/iPhone/i) ||
            userAgent.match(/iPad/i) ||
            userAgent.match(/Android/i)
          ) {
            if (
              // Number(
              //   state.offset * scrollThreshold + el.offsetHeight >
              //     Number(0.96 * scrollThreshold + el.offsetHeight)
              // )
              state.offset >= 0.999 &&
              !TopScroll
            ) {
              TopScroll = true;
              scrollToTop(el);
              // setTimeout(() => {
              //   TopScroll = false;
              // }, 1000);
              disableScroll = true;
            }

            if (state.offset <= 0.001 && !BottomScroll) {
              going1 = true;
              BottomScroll = true;
              scrollToBottom(el);
              // setTimeout(() => {
              //   BottomScroll = false;
              // }, 1000);
              disableScroll = true;
            } else {
              going1 = false;
            }
          } else {
            if (
              Number(
                state.offset * scrollThreshold + el.offsetHeight >
                  Number(0.998 * scrollThreshold + el.offsetHeight)
              )
            ) {
              scrollToTop(el);
              disableScroll = true;
            }

            if (state.offset * scrollThreshold < 0.001 * scrollThreshold) {
              scrollToBottom(el);
              disableScroll = true;
            }
          }
        }
        if (disableScroll) setTimeout(() => (disableScroll = false), 4);
      }
    };

    // Attach the event listener to the window
    el.addEventListener("scroll", onScroll, { passive: false });
    el.addEventListener("wheel", onScroll, { passive: false });
    // el.addEventListener("touchmove", onScroll, { passive: false });

    requestAnimationFrame(() => (firstRun = false));

    return () => {
      // Remove the event listeners from the window
      el.removeEventListener("scroll", onScroll);
      el.removeEventListener("wheel", onScroll, { passive: false });
      // el.removeEventListener("touchmove", onScroll, { passive: false });
    };
  }, [
    el,
    size,
    infinite,
    state,
    invalidate,
    vertical,
    enableScroll,
    userAgent,
  ]);

  let last = 0;
  useFrame((_, delta) => {
    state.offset = THREE.MathUtils.damp(
      (last = state.offset),
      scroll.current,
      // 1,
      scrollBuffer.current,
      delta
    );

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/Android/i)
    ) {
      if (state.offset * 100 <= 0.15) {
        setTimeout(() => {
          BottomScroll = false;
        }, 2000);
        BottomScroll = true;
        scrollToBottom(el);
      }

      if (scroll.current * 100 >= 99.97 && !BottomScroll) {
        TopScroll = true;
        setTimeout(() => {
          TopScroll = false;
        }, 2000);
        scrollToTop(el);
      }
    } else {
      if (state.offset * 100 >= 99.89 && !BottomScroll) {
        TopScroll = true;
        setTimeout(() => {
          TopScroll = false;
        }, 2000);
        scrollToTop(el);
      }
    }

    if (showContactPage && scroll.current >= 0.989) {
      scrollToTop(el);
    }

    state.delta = THREE.MathUtils.damp(
      state.delta,
      Math.abs(last - state.offset),
      damping,
      delta
    );
    if (state.delta > eps) invalidate();
    // if (window?.document?.getElementById("addcontent")?.innerHTML) {
    //   window.document.getElementById(
    //     "addcontent"
    //   ).innerHTML = `scroll.current * 100 : ${scroll?.current * 100 ?? ""} ,`;
    // }
  });
  return <context.Provider value={state}>{children}</context.Provider>;
};

const ScrollCanvas = React.forwardRef(({ children }, ref) => {
  const group = React.useRef(null);

  const state = useScroll();

  const { width, height } = useThree((state) => state.viewport);
  useFrame(() => {
    group.current.position.x = 0;
    group.current.position.y = height * (state.pages - 1) * state.offset;
  });
  return <group ref={mergeRefs([ref, group])}>{children}</group>;
});

export const Scroll = React.forwardRef(({ html, ...props }, ref) => {
  const El = ScrollCanvas;
  return <El ref={ref} {...props} />;
});
