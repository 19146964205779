import * as THREE from "three";

import createIndices from "quad-indices";
import vertices from "../utils/vertices";
import utils from "../utils/utils";
import { BufferAttribute } from "three";
import { createLayout } from "./layout";

function TextGeometryComponent(opt) {
  let geometry = null;

  const bufferGeometry = new THREE.BufferGeometry();

  function tg(opt) {
    if (typeof opt === "string") {
      opt = { text: opt };
    }
    this._opt = Object.assign({}, opt);
    if (opt) this.update(opt);
  }

  tg.prototype.update = function (opt) {
    if (typeof opt === "string") {
      opt = { text: opt };
    }

    opt = Object.assign({}, this._opt, opt);

    if (!opt.font) {
      throw new TypeError("must specify a { font } in options");
    }

    this.layout = createLayout(opt);

    const flipY = opt.flipY !== false;
    const font = opt.font.data;
    const texWidth = font.common.scaleW;
    const texHeight = font.common.scaleH;
    const glyphs = this.layout.glyphs.filter(function (glyph) {
      const bitmap = glyph.data;
      return bitmap.width * bitmap.height > 0;
    });

    this.visibleGlyphs = glyphs;

    const positions = vertices.positions(glyphs);
    const uvs = vertices.uvs(glyphs, texWidth, texHeight, flipY);
    const indices = createIndices([], {
      clockwise: true,
      type: "uint16",
      count: glyphs.length,
    });

    bufferGeometry.setIndex(indices);
    bufferGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 2)
    );
    bufferGeometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));

    if (!opt.multipage && "page" in bufferGeometry.attributes) {
      this.removeAttribute("page");
    } else if (opt.multipage) {
      const pages = vertices.pages(glyphs);
      this.setAttribute("page", new BufferAttribute(pages, 1));
    }
  };

  tg.prototype.computeBoundingSphere = function () {
    if (bufferGeometry.boundingSphere === null) {
      bufferGeometry.boundingSphere = new THREE.Sphere();
    }

    var positions = bufferGeometry.attributes.position.array;
    var itemSize = bufferGeometry.attributes.position.itemSize;
    if (!positions || !itemSize || positions.length < 2) {
      bufferGeometry.boundingSphere.radius = 0;
      bufferGeometry.boundingSphere.center.set(0, 0, 0);
      return;
    }
    utils.computeSphere(positions, bufferGeometry.boundingSphere);
    if (isNaN(bufferGeometry.boundingSphere.radius)) {
      console.error(
        "THREE.BufferGeometry.computeBoundingSphere(): " +
          "Computed radius is NaN. The " +
          '"position" attribute is likely to have NaN values.'
      );
    }
  };

  tg.prototype.computeBoundingBox = function () {
    if (bufferGeometry.boundingBox === null) {
      bufferGeometry.boundingBox = new THREE.Box3();
    }

    var bbox = bufferGeometry.boundingBox;
    var positions = bufferGeometry.attributes.position.array;
    var itemSize = bufferGeometry.attributes.position.itemSize;
    if (!positions || !itemSize || positions.length < 2) {
      bbox.makeEmpty();
      return;
    }
    utils.computeBox(positions, bbox);
  };

  // Create an instance of tg
  const textGeom = new tg(opt);

  // Attach to the state
  geometry = textGeom;

  // Since we removed the useEffect, we should manually add and remove the geometry to/from the scene.
  if (geometry) {
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();
  }

  // Attach to the state

  return { geometry: textGeom, bufferGeometry };
}

export default TextGeometryComponent;
