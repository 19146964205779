import React, { useRef, useEffect, useContext, useState } from "react";
import { useVideoTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { LoadingContext } from "../../context/loading.context";
import { useScroll } from "../ScrollControls";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { ScrollContext } from "../../context/scroll.context";

const VideoLoader = ({ width, height, position, scale, videoURl }) => {
  var userAgent = navigator.userAgent;

  const texture = useVideoTexture(videoURl, undefined, {
    playsInline: false,
    muted: true,
    crossOrigin: "anonymous",
  });
  const meshRef = useRef();
  const videoRef = useRef();
  const isVideoPlaying = useRef(false);
  const { scroll, el } = useScroll();
  const { setLoading } = useContext(LoadingContext);
  const [direction, setDirection] = useState();
  const prevScrollRef = useRef(null);
  const disableScrollRef = useRef(true);
  const hehe = useRef(false);
  const hehe2 = useRef(false);

  const handleLoadedMetadata = () => {
    videoRef.current.loop = true;
    videoRef.current?.removeEventListener(
      "loadedmetadata",
      handleLoadedMetadata
    );
    videoRef.current.play();
    videoRef.current.loop = true;
  };

  useEffect(() => {
    if (texture) {
      setLoading("section4", true);
      videoRef.current = texture.image;
      videoRef.current?.addEventListener(
        "loadedmetadata",
        handleLoadedMetadata
      );
      videoRef.current.playsInline = true;
      videoRef.current.muted = true;
    }

    return () => {
      videoRef.current?.removeEventListener(
        "loadedmetadata",
        handleLoadedMetadata
      );
    };
  }, [texture]);

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i)) {
      if (currentScroll >= 4.51) hehe.current = true;

      if (currentScroll <= 0.31) hehe.current = false;

      if (currentScroll >= 99.89) hehe2.current = true;

      if (currentScroll <= 92.99) hehe2.current = false;
    }

    if (userAgent.match(/Android/i)) {
      if (currentScroll >= 4.51) hehe.current = true;

      if (currentScroll <= 0.26) hehe.current = false;

      if (currentScroll >= 98.4) hehe2.current = true;

      if (currentScroll <= 92.99) hehe2.current = false;
    }

    if (texture) {
      texture.needsUpdate = true;
    }

    if (currentScroll < prevScrollRef.current && direction !== "top") {
      setDirection("top");
    }

    // set direction to down
    if (currentScroll > prevScrollRef.current && direction !== "down") {
      setDirection("down");
    }

    if (currentScroll > 94 && currentScroll < 96) {
      videoRef.current.currentTime = 0.5;
    }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/Android/i)
    ) {
      if (
        currentScroll > 95 &&
        currentScroll < 99.85
        // &&
        // currentScroll < prevScrollRef.current
      ) {
        // scroll.current += 0.07 / 100;
        // el.scrollTop += 1;
        // if (currentScroll > 99 && currentScroll < 99.89) {
        //   setScrollTopToPercentage(99.7, el);
        // }
      }
    }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
      //  ||
      // userAgent.match(/Android/i)
    ) {
      if (
        currentScroll > 93 &&
        currentScroll < 99.8 &&
        // currentScroll > prevScrollRef.current &&
        !hehe2.current
      ) {
        scroll.current += 0.1 / 100;
        if (currentScroll > 99 && currentScroll < 99.8) {
          setScrollTopToPercentage(99.8, el);
        }
      }
    }

    if (userAgent.match(/Android/i)) {
      if (
        currentScroll > 93 &&
        currentScroll < 99.56 &&
        currentScroll > prevScrollRef.current

        // &&
        // !hehe2.current
      ) {
        scroll.current += 0.1 / 100;
        if (currentScroll > 99 && currentScroll < 99.56) {
          setScrollTopToPercentage(99.56, el);
        }
      }
      if (
        currentScroll > 93 &&
        currentScroll < 99.89 &&
        currentScroll < prevScrollRef.current
      ) {
        scroll.current -= 0.1 / 100;
        if (currentScroll > 93 && currentScroll < 93.8) {
          setScrollTopToPercentage(93, el);
        }
      }
    }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
      //  ||
      // userAgent.match(/Android/i)
    ) {
      if (
        currentScroll > 0.2 &&
        currentScroll < 4.5 &&
        // currentScroll > prevScrollRef.current &&
        !hehe.current
      ) {
        scroll.current += 0.1 / 100;
      }
    }

    if (userAgent.match(/Android/i)) {
      if (
        currentScroll > 0.2 &&
        currentScroll < 4.5 &&
        currentScroll > prevScrollRef.current &&
        !hehe.current
      ) {
        scroll.current += 0.1 / 100;
      }

      if (
        currentScroll > 0.2 &&
        currentScroll < 4.5 &&
        // currentScroll < prevScrollRef.current &&
        hehe.current
      ) {
        scroll.current -= 0.1 / 100;
        if (currentScroll > 0.21 && currentScroll < 0.7) {
          setScrollTopToPercentage(0.21, el);
        }
      }
    }

    if (currentScroll > 100) {
      scroll.current = 0.99999;
    }

    if (currentScroll < 71) {
      disableScrollRef.current = true;
    }

    // if (currentScroll > 99.9 || currentScroll < 0.2) {
    //   setEnableScroll(false);

    //   setTimeout(() => {
    //     setEnableScroll(true);
    //   }, 2000);
    // }

    if (videoRef.current) {
      if (!isVideoPlaying.current) {
        videoRef.current.play();
        isVideoPlaying.current = true;
      }
    } else {
      if (isVideoPlaying.current) {
        videoRef.current.pause();
        isVideoPlaying.current = false;
      }
    }

    prevScrollRef.current = currentScroll;
  });

  if (!texture) {
    return null;
  }

  return (
    <>
      <mesh ref={meshRef} position={position} scale={scale}>
        <planeGeometry args={[width, height, 1, 1]} />
        <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
      </mesh>
    </>
  );
};

export default VideoLoader;
