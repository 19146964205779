import React, { useEffect, useState } from "react";
import PngLoader from "../pngLoader.component";
import VideoLoader from "./videoLoader.component";
import { useThree } from "@react-three/fiber";

const Section4 = () => {
  const { width } = useThree((state) => state.viewport);
  const [videoUrl, setVideoUrl] = useState(
    "assets/video/SECTION_4_FINAL_NEW_1 _Compressed2.mp4"
  );
  const [videoWidth, setVideoWidth] = useState();

  useEffect(() => {
    if (width < 5.67) {
      setVideoUrl("assets/video/vertical_final.mp4");
      setVideoWidth(width * 0.14);
    } else if (width < 7.7 && width >= 5.67) {
      setVideoUrl("assets/video/horizontal_final.mp4");
      setVideoWidth(width * 0.13);
    } else if (width >= 7.7 && width < 11.34) {
      setVideoUrl("assets/video/horizontal_final.mp4");
      setVideoWidth(width * 0.13);
    } else if (width >= 11.34 && width <= 14.5) {
      setVideoUrl("assets/video/horizontal_final.mp4");
      setVideoWidth(width * 0.13);
    } else {
      setVideoUrl("assets/video/horizontal_final.mp4");
      setVideoWidth(width * 0.13);
    }
  }, [width]);

  return (
    <>
      {/* upper video */}
      <VideoLoader
        height={1.1}
        width={videoWidth}
        scale={[1, 1, 1]}
        position={[0, -0.05, 4.01]}
        videoURl={videoUrl}
      />

      {/* lower video */}
      <VideoLoader
        height={1.1}
        width={videoWidth}
        scale={[1, 1, 1]}
        position={[0, -26.98, 4.01]}
        videoURl={videoUrl}
      />

      {/* lower bg image */}
      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/Section-4-Image.png"
        position={[0, -11.1, 2]}
        zoom={0.7}
        scale={[4, 1.42, 1]}
      />

      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/Section-5-Image.png"
        position={[0, -6.8, 1]}
        zoom={0.7}
        scale={[4, 5, 1]}
      />

      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/outdoor-loop-new.png"
        position={[0, 0.5, 1]}
        zoom={0.7}
        scale={[4, 4, 1]}
      />

      {/* upper bg image  */}
      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/Section-4-Image.png"
        position={[0, -0, 1.9]}
        zoom={0.7}
        scale={[4, 1.4, 1]}
      />

      <PngLoader
        renderOrder={-10}
        transparent={true}
        path="assets/img/Section-5-Image.png"
        position={[0, -1, 1.6]}
        scale={[5, 2.8, 1]}
        rotation={[0, 0, 0.1]}
      />
    </>
  );
};

export default Section4;
