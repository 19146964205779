import xtend from "xtend";
import { lines1 } from "./wordWrap";

function numtype(num, def) {
  return typeof num === "number" ? num : typeof def === "number" ? def : 0;
}

const X_HEIGHTS = [
  "x",
  "e",
  "a",
  "o",
  "n",
  "s",
  "r",
  "c",
  "u",
  "m",
  "v",
  "w",
  "z",
];
const M_WIDTHS = ["m", "w"];
const CAP_HEIGHTS = [
  "H",
  "I",
  "N",
  "E",
  "F",
  "K",
  "L",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const TAB_ID = "\t".charCodeAt(0);
const SPACE_ID = " ".charCodeAt(0);
const ALIGN_LEFT = 0;
const ALIGN_CENTER = 1;
const ALIGN_RIGHT = 2;

export function createLayout(opt) {
  return new TextLayout(opt);
}

//create lookups for private vars
function wrapper(name) {
  return new Function(
    ["return function " + name + "() {", "  return this._" + name, "}"].join(
      "\n"
    )
  )();
}

function getGlyphById(font, id) {
  if (!font.chars || font.chars.length === 0) return null;

  var glyphIdx = findChar(font.chars, id);
  if (glyphIdx >= 0) return font.chars[glyphIdx];
  return null;
}

function getXHeight(font) {
  for (var i = 0; i < X_HEIGHTS.length; i++) {
    var id = X_HEIGHTS[i].charCodeAt(0);
    var idx = findChar(font.chars, id);
    if (idx >= 0) return font.chars[idx].height;
  }
  return 0;
}

function getMGlyph(font) {
  for (var i = 0; i < M_WIDTHS.length; i++) {
    var id = M_WIDTHS[i].charCodeAt(0);
    var idx = findChar(font.chars, id);
    if (idx >= 0) return font.chars[idx];
  }
  return 0;
}

function getCapHeight(font) {
  for (var i = 0; i < CAP_HEIGHTS.length; i++) {
    var id = CAP_HEIGHTS[i].charCodeAt(0);
    var idx = findChar(font.chars, id);
    if (idx >= 0) return font.chars[idx].height;
  }
  return 0;
}

function getKerning(font, left, right) {
  if (!font.kernings || font.kernings.length === 0) return 0;

  var table = font.kernings;
  for (var i = 0; i < table.length; i++) {
    var kern = table[i];
    if (kern.first === left && kern.second === right) return kern.amount;
  }
  return 0;
}

function getAlignType(align) {
  if (align === "center") return ALIGN_CENTER;
  else if (align === "right") return ALIGN_RIGHT;
  return ALIGN_LEFT;
}

function findChar(array, value, start) {
  start = start || 0;
  for (var i = start; i < array.length; i++) {
    if (array[i].id === value) {
      return i;
    }
  }
  return -1;
}

class TextLayout {
  constructor(opt) {
    this.glyphs = [];
    this._measure = this.computeMetrics.bind(this);
    this.update(opt);
  }

  update(opt) {
    opt = xtend(
      {
        measure: this._measure,
      },
      opt
    );
    this._opt = opt;

    this._opt.tabSize = 0;

    if (!opt.font) {
      throw new Error("must provide a valid bitmap font");
    }

    const glyphs = this.glyphs;
    const text = opt.text || "";
    const font = opt.font.data;
    this._setupSpaceGlyphs(font);
    const lines = lines1(text, opt);

    const minWidth = opt.width || 0;

    glyphs.length = 0;

    const maxLineWidth = lines.reduce(
      (prev, line) => Math.max(prev, line.width, minWidth),
      0
    );

    let x = 0;
    let y = 0;
    const lineHeight = numtype(opt.lineHeight, font.common.lineHeight);
    const baseline = font.common.base;
    const descender = lineHeight - baseline;
    const letterSpacing = opt.letterSpacing || 0;
    const height = lineHeight * lines.length - descender;
    const align = getAlignType(this._opt.align);

    y -= height;

    this._width = maxLineWidth;
    this._height = height;
    this._descender = lineHeight - baseline;
    this._baseline = baseline;
    this._xHeight = getXHeight(font);
    this._capHeight = getCapHeight(font);
    this._lineHeight = lineHeight;
    this._ascender = lineHeight - descender - this._xHeight;
    lines.forEach((line, lineIndex) => {
      const start = line.start;
      const end = line.end;
      const lineWidth = line.width;

      let lastGlyph;
      for (let i = start; i <= end; i++) {
        const id = text.charCodeAt(i);
        const glyph = this.getGlyph(font, id);
        if (glyph) {
          if (lastGlyph) {
            x += getKerning(font, lastGlyph.id, glyph.id);
          }

          let tx = x;
          if (align === ALIGN_CENTER) {
            tx += (maxLineWidth - lineWidth) / 2;
          } else if (align === ALIGN_RIGHT) {
            tx += maxLineWidth - lineWidth;
          }

          glyphs.push({
            position: [tx, y],
            data: glyph,
            index: i,
            line: lineIndex,
          });
          x += glyph.xadvance + letterSpacing;
          lastGlyph = glyph;
        }
      }

      y += lineHeight;
      x = 0;
    });

    this._linesTotal = lines.length;
    this.lines = lines;
  }

  _setupSpaceGlyphs(font) {
    this._fallbackSpaceGlyph = null;
    this._fallbackTabGlyph = null;

    if (!font.chars || font.chars.length === 0) {
      return;
    }

    const space =
      getGlyphById(font, SPACE_ID) || getMGlyph(font) || font.chars[0];
    const tabWidth = this._opt.tabSize * space.xadvance;

    this._fallbackSpaceGlyph = space;
    this._fallbackTabGlyph = {
      ...space,
      x: 0,
      y: 0,
      xadvance: tabWidth,
      id: TAB_ID,
      xoffset: 0,
      yoffset: 0,
      width: 0,
      height: 0,
    };
  }

  getGlyph(font, id) {
    const glyph = getGlyphById(font, id);
    if (glyph) {
      return glyph;
    } else if (id === TAB_ID) {
      return this._fallbackTabGlyph;
    } else if (id === SPACE_ID) {
      return this._fallbackSpaceGlyph;
    }
    return null;
  }

  computeMetrics(text, start, end, width) {
    const letterSpacing = this._opt.letterSpacing || 0;
    const font = this._opt.font;
    let curPen = 0;
    let curWidth = 0;
    let count = 0;
    let lastGlyph;

    if (!font.chars || font.chars.length === 0) {
      return {
        start,
        end: start,
        width: 0,
      };
    }

    end = Math.min(text.length, end);
    for (let i = start; i < end; i++) {
      const id = text.charCodeAt(i);
      const glyph = this.getGlyph(font, id);
      if (glyph) {
        const xoff = glyph.xoffset;
        const kern = lastGlyph
          ? this.getKerning(font, lastGlyph.id, glyph.id)
          : 0;
        curPen += kern;

        const nextPen = curPen + glyph.xadvance + letterSpacing;
        const nextWidth = curPen + glyph.width;

        if (nextWidth >= width || nextPen >= width) {
          break;
        }

        curPen = nextPen;
        curWidth = nextWidth;
        lastGlyph = glyph;
      }
      count++;
    }

    if (lastGlyph) {
      curWidth += lastGlyph.xoffset;
    }

    return {
      start,
      end: start + count,
      width: curWidth,
    };
  }
}

//getters for the private vars
[
  "width",
  "height",
  "descender",
  "ascender",
  "xHeight",
  "baseline",
  "capHeight",
  "lineHeight",
].forEach(addGetter);

function addGetter(name) {
  Object.defineProperty(TextLayout.prototype, name, {
    get: wrapper(name),
    configurable: true,
  });
}
