import { useEffect, useState } from "react";
import MSDFShader from "../utils/textGradient";
import * as THREE from "three";
import { fragment } from "../shaders/fragment";
import { vertex } from "../shaders/vertex";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { useFrame, useThree } from "@react-three/fiber";
import TextGeometryComponent from "../utils/textGeometry";

const TextComponent = ({
  text,
  align,
  particlesCount,
  scale,
  position,
  pointSize,
}) => {
  const { scene } = useThree();
  const [generatedTextMesh, setGeneratedTextMesh] = useState(null);
  const [generatedPoints, setGeneratedPoints] = useState(null);
  // for text movement
  useFrame(({ clock }) => {
    if (generatedTextMesh) {
      generatedTextMesh.material.uniforms.time.value = clock.getElapsedTime();
    }
    if (generatedPoints) {
      generatedPoints.material.uniforms.time.value = clock.getElapsedTime();
    }
  });

  // for loading fontTexture
  useEffect(() => {
    const loadFontTexture = async () => {
      try {
        const texture = await new THREE.TextureLoader().loadAsync(
          "/font/manifold.png"
        );

        addText(texture);
      } catch (error) {
        console.error("Error loading font texture:", error);
      }
    };
    loadFontTexture();
  }, [text, scale, position]);

  // mouse events
  const setupMouseEvents = (materialText, material) => {
    const handleMouseMove = (event) => {
      const mouse = {
        x: event.clientX / window.innerWidth,
        y: event.clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const handleTouchMove = (event) => {
      const mouse = {
        x: event.touches[0].clientX / window.innerWidth,
        y: event.touches[0].clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const canvas = document.querySelector("canvas");
    const handleWindowResize = (event) => {
      materialText.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
      material.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
    };
    const handleTouchEnd = () => {
      materialText.uniforms.uMouse.value = new THREE.Vector2(0, 0);
      material.uniforms.uMouse.value = new THREE.Vector2(0, 0);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  // addText
  const addText = (fontTexture) => {
    const loader = new FontLoader();
    loader.load("/font/manifold.json", function (font) {
      const geometry = TextGeometryComponent({
        text: text,
        font: font,
        align: align,
        flipY: fontTexture.flipY,
        scene,
      });

      const material = new THREE.RawShaderMaterial(
        MSDFShader({
          map: fontTexture,
          transparent: true,
          color: 0xffffff,
          side: THREE.DoubleSide,
          glslVersion: THREE.GLSL3,
          depthTest: true,
          depthWrite: true,
        })
      );

      let layout = geometry.geometry.layout;
      let textMesh = new THREE.Mesh(geometry.bufferGeometry, material);

      textMesh.scale.set(...scale);
      textMesh.position.set(...position);
      setGeneratedTextMesh(textMesh);
      setupMouseEvents(textMesh.material, material);
      addObjects(layout, textMesh, scene);
    });
  };

  // addObjects
  const addObjects = (layout, textMesh) => {
    const textBoundingBox = new THREE.Box3().setFromObject(textMesh);

    let number = particlesCount;
    let geo = new THREE.BufferGeometry();
    let pos = [];

    for (let i = 0; i < number; i++) {
      // Generate random positions within the bounding box of the text mesh
      let x = THREE.MathUtils.randFloat(
        textBoundingBox.min.x,
        textBoundingBox.max.x
      );
      let y = THREE.MathUtils.randFloat(
        textBoundingBox.min.y,
        textBoundingBox.max.y
      );
      let z = THREE.MathUtils.randFloat(
        textBoundingBox.min.z,
        textBoundingBox.max.z
      );

      pos.push(x, y, z);
    }

    pos = new Float32Array(pos);
    geo.setAttribute("position", new THREE.BufferAttribute(pos, 3));

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        viewport: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        pointSize: { value: pointSize },
        uMouse: { value: new THREE.Vector2(0, 0) },
        resolution: { value: new THREE.Vector4() },
        uColor: { value: new THREE.Color(0xffffff) },
      },
      depthTest: true,
      depthWrite: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    const points = new THREE.Points(geo, material);
    setGeneratedPoints(points);
    addTimer(points);
    setupMouseEvents(textMesh.material, material);
  };

  // movement of particles
  const addTimer = (mesh) => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const areaBounds = {
      minX: boundingBox.min.x,
      maxX: boundingBox.max.x,
      minY: boundingBox.min.y,
      maxY: boundingBox.max.y,
      minZ: boundingBox.min.z,
      maxZ: boundingBox.max.z,
    };
    const maxVelocity = 0.08; // Maximum speed for particles
    const particlesCount = mesh.geometry.attributes.position.count;
    const velocities = new Array(particlesCount * 3)
      .fill(0)
      .map(() => (Math.random() - 0.5) * maxVelocity);

    setInterval(() => {
      const positions = mesh.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i++) {
        positions[i] += velocities[i];

        // Check and adjust position to stay within bounds
        if (i % 3 === 0) {
          // X-axis
          if (
            positions[i] < areaBounds.minX ||
            positions[i] > areaBounds.maxX
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minX,
            Math.min(areaBounds.maxX, positions[i])
          );
        } else if (i % 3 === 1) {
          // Y-axis
          if (
            positions[i] < areaBounds.minY ||
            positions[i] > areaBounds.maxY
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minY,
            Math.min(areaBounds.maxY, positions[i])
          );
        } else {
          // Z-axis
          if (
            positions[i] < areaBounds.minZ ||
            positions[i] > areaBounds.maxZ
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minZ,
            Math.min(areaBounds.maxZ, positions[i])
          );
        }
      }
      // Update the buffer geometry to reflect changes
      mesh.geometry.attributes.position.needsUpdate = true;
    }, 100);
  };

  return (
    <group>
      {generatedTextMesh && <primitive object={generatedTextMesh} />}
      {generatedPoints && <primitive object={generatedPoints} />}
    </group>
  );
};
export default TextComponent;
