import React, { useRef, useEffect, useState, useContext } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { BoxGeometry, MeshBasicMaterial, Mesh } from "three";
import { ScrollContext } from "../../context/scroll.context";
import { radToDeg } from "three/src/math/MathUtils";
import { Section8Context } from "../../context/section8.context";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import { useScroll } from "../ScrollControls";
import { LoadingContext } from "../../context/loading.context";
import * as THREE from "three";
import gsap from "gsap";
import PngLoader from "../pngLoader.component";

const Carousel3D = ({
  position,
  scale = [1, 1, 1],
  radius,
  direction,
  setDirection,
  flowerRotation,
  isInDestination,
  modelScales,
  leftArrowPosition,
  rightArrowPosition,
  arrowScale,
  buttonPosition,
  buttonScale,
}) => {
  const [rotating, setIsRotating] = useState(false);
  const [imageOpacity, setImageOpacity] = useState();
  const [pointerMove, setPointerMove] = useState(false);
  const [movementDirection, setMovementDirection] = useState("");
  const [model, setModel] = useState(0);
  const [scrollValue, setScrollValue] = useState({
    scrollValue: 0,
    scrollPercentage: 0,
  });
  const [lastY, setLastY] = useState();
  const [isSection4, setIsSection4] = useState(true);
  const groupRef = useRef();
  const isPointerDown = useRef(false);
  const dropingModalPosition = useRef(null);
  const pointerStartPosition = useRef({ x: 0 });
  const modelRefs = useRef([]);
  const speedRef = useRef(0.006);
  const prevScrollRef = useRef(null);
  const { setEnableScroll, enableScroll } = useContext(ScrollContext);
  const { showContactPage } = useContext(Section8Context);
  const { setLoading } = useContext(LoadingContext);
  const { scroll, el } = useScroll();
  const previousTimeRef = useRef(0);
  const rotationSpeed = 0.26;
  let debounceValue = null;
  const rotationFactor = 0.1;
  let newSpeed = rotationSpeed;
  const { scene } = useThree();
  var userAgent = navigator.userAgent;
  const [rotateLeft, setRotateLeft] = useState(false);
  const [rotateRight, setRotateRight] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  const { width } = useThree((state) => state.viewport);
  useEffect(() => {
    setLastY(radToDeg(flowerRotation).toFixed(0));
  }, [flowerRotation]);

  const modelPaths = [
    "/assets/3d-Models/Dry_Sift_7K_new.glb",
    "/assets/3d-Models/BUBBA KUSH/BUBBA_7K_NEW.glb",
    "/assets/3d-Models/STRAWBERRY GELATO/Strawberry_Gelato_7K_NEW.glb",
  ];

  const models = useLoader(GLTFLoader, modelPaths);

  useEffect(() => {
    // if (radius) {
    const angleIncrement = (2 * Math.PI) / modelPaths.length;

    models.forEach((gltf, index) => {
      const model = gltf.scene;
      const angle = index * angleIncrement + (2 * Math.PI) / 3;
      const x = Math.cos(angle) * radius;
      const y = 0;
      const z = Math.sin(angle) * radius;
      model.scale.set(...modelScales[index]);
      model.position.set(x, y, z);
      modelRefs.current[index] = model;
      groupRef.current.add(model);
      if (index === 2) {
        setTimeout(() => {
          setLoading("flowers", true);
        }, 2000);
      }
    });
  }, []);

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "auto";
  };

  useEffect(() => {
    if (
      modelRefs.current &&
      modelRefs.current.length &&
      modelRefs.current[model] &&
      modelRefs.current[model].position
    ) {
      dropingModalPosition.current = modelRefs.current[model].position.z;
    }
  }, [
    model,
    modelRefs.current,
    modelRefs.current.length,
    modelRefs.current[model],
  ]);

  useFrame((state, delta) => {
    const currentTime = state.clock.elapsedTime;
    const deltaTime = currentTime - previousTimeRef.current;
    const movementSpeed = 0.02 * deltaTime * 60;
    previousTimeRef.current = currentTime;
    const currentValue = scroll.current * 10 * 10;

    if (currentValue > 1 && currentValue < 5) {
      setIsSection4(true);
    }

    if (
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/Android/i)
    ) {
      if (currentValue >= 4.5 && currentValue <= 7) {
        scroll.current += 0.14 / 100;
      }
    } else {
      if (currentValue >= 2 && currentValue <= 7) {
        scroll.current += 0.14 / 100;
      }
    }

    if (currentValue > 6.2 && currentValue < 7) {
      setScrollTopToPercentage(6.8, el);
    }

    if (
      currentValue < prevScrollRef.current &&
      prevScrollRef.current - currentValue > 0.5 &&
      direction !== "top"
    ) {
      setDirection("top");
    }

    if (
      currentValue > prevScrollRef.current &&
      currentValue - prevScrollRef.current > 0.5 &&
      direction !== "down"
    ) {
      setDirection("down");
    }

    if (
      (rotateLeft || rotateRight) &&
      radToDeg(groupRef.current.rotation.y).toFixed(0) <=
        parseFloat(lastY) + 120 &&
      currentValue > 6.2 &&
      currentValue < 7.7 &&
      modelRefs.current[model].position.y >= 0
    ) {
      setEnableScroll(false);
      let targetY;
      if (rotateLeft) {
        targetY = groupRef.current.rotation.y - rotationSpeed; // Rotate left
      } else {
        targetY = groupRef.current.rotation.y + rotationSpeed; // Rotate right
      }

      if (width < 7) {
        modelRefs.current.forEach((model) => {
          if (rotateLeft) {
            model.rotation.y +=
              navigator && navigator.platform.toLowerCase().includes("mac")
                ? 0.005
                : 0.01;
          }
          if (rotateRight) {
            model.rotation.y -=
              navigator && navigator.platform.toLowerCase().includes("mac")
                ? 0.005
                : 0.01;
          }
        });
      }
      groupRef.current.rotation.y = THREE.MathUtils.lerp(
        groupRef.current.rotation.y,
        targetY,
        navigator && navigator.platform.toLowerCase().includes("mac")
          ? 0.09
          : 0.18
      );
      const newRotation =
        ((radToDeg(groupRef.current.rotation.y).toFixed(2) / 120) * 120) % 120;

      if (
        ((newRotation <= radToDeg(flowerRotation) ||
          newRotation <= radToDeg(flowerRotation) + 1) &&
          (newRotation >= radToDeg(flowerRotation) - 1 ||
            newRotation >= radToDeg(flowerRotation) - 2)) ||
        ((newRotation > radToDeg(flowerRotation) - 120 ||
          newRotation > radToDeg(flowerRotation) - 121) &&
          (newRotation < radToDeg(flowerRotation) - 119 ||
            newRotation < radToDeg(flowerRotation) - 118))
      ) {
        setLastY(radToDeg(groupRef.current.rotation.y).toFixed(0));
        setRotateLeft(false);
        setRotateRight(false);
        setEnableScroll(true);
      }
    }

    if (
      (isPointerDown &&
        rotating &&
        currentValue > 6.2 &&
        currentValue < 7.7 &&
        modelRefs.current[model].position.y >= 0) ||
      (!isPointerDown.current &&
        rotating &&
        modelRefs.current[model].position.y >= 0)
    ) {
      // for carosuel rotation
      setEnableScroll(false);
      scroll.current = scrollValue.scrollValue;
      el.scrollTop = scrollValue.scrollPercentage;
      const rotationDelta = rotationSpeed * rotationFactor;
      if (
        radToDeg(groupRef.current.rotation.y).toFixed(0) <=
        parseFloat(lastY) + 120
      ) {
        if (width < 7) {
          modelRefs.current.forEach((model) => {
            model.rotation.y +=
              movementDirection === "left"
                ? navigator && navigator.platform.toLowerCase().includes("mac")
                  ? 0.005
                  : 0.01
                : navigator && navigator.platform.toLowerCase().includes("mac")
                ? -0.005
                : -0.01;
          });
        } else {
          modelRefs.current.forEach((model) => {
            model.rotation.y += movementDirection === "left" ? 0.024 : -0.024;
          });
        }

        // LERP
        let targetY =
          groupRef.current.rotation.y +
          (movementDirection === "left" ? -rotationSpeed : rotationSpeed);

        groupRef.current.rotation.y = THREE.MathUtils.lerp(
          groupRef.current.rotation.y,
          targetY,
          navigator && navigator.platform.toLowerCase().includes("mac")
            ? 0.09
            : 0.18
        );
      }

      const newRotation =
        ((radToDeg(groupRef.current.rotation.y).toFixed(2) / 120) * 120) % 120;

      if (
        rotating &&
        (!isPointerDown.current || !pointerMove) &&
        (((newRotation <= radToDeg(flowerRotation) ||
          newRotation <= radToDeg(flowerRotation) + 1) &&
          (newRotation >= radToDeg(flowerRotation) - 1 ||
            newRotation >= radToDeg(flowerRotation) - 2)) ||
          ((newRotation > radToDeg(flowerRotation) - 120 ||
            newRotation > radToDeg(flowerRotation) - 121) &&
            (newRotation < radToDeg(flowerRotation) - 119 ||
              newRotation < radToDeg(flowerRotation) - 118)))
      ) {
        setIsRotating(false);
        setLastY(radToDeg(groupRef.current.rotation.y).toFixed(0));
        setPointerMove(false);
        setEnableScroll(true);
      }
    }

    if (modelRefs.current[model]) {
      if (
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0)) % 360 === 0 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0)) % 360 ===
          359 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0)) % 360 ===
          -359 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0)) % 360 ===
          -1 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0)) % 360 === 1
      ) {
        setModel(0);
      }

      if (
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 120) %
          360 ===
          0 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 120) %
          360 ===
          359 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 120) %
          360 ===
          -1 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 120) %
          360 ===
          1 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 120) %
          360 ===
          -359 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 120) %
          360 ===
          -358
      ) {
        setModel(1);
      }
      if (
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          0 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          -1 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          -2 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          359 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          358 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          -359 ||
        (parseFloat(lastY) - radToDeg(flowerRotation).toFixed(0) - 240) %
          360 ===
          1
      ) {
        setModel(2);
      }

      if (currentValue < 3) {
        modelRefs.current[model].position.y = 0;
      }

      if (currentValue < 8) {
        setImageOpacity(1);
      }

      if (currentValue >= 6 && currentValue < 8) {
        if (modelRefs.current[model].position.y <= 0) {
          // if (
          //   modelRefs.current[model].position.y < -1.1 &&
          //   currentValue < 29.5
          // ) {
          //   modelRefs.current[model].position.y = -1.1;
          // }
          setImageOpacity(0);
          modelRefs.current[model].position.y += 0.024;
        }
      }

      if (currentValue >= 8 && currentValue < 9) {
        setImageOpacity(0);
        if (modelRefs.current[model].position.y > -0.25) {
          modelRefs.current[model].position.y -= 0.024;
        }
        if (modelRefs.current[model].position.y < -0.25) {
          modelRefs.current[model].position.y += 0.024;
        }
      }

      if (currentValue >= 9 && currentValue < 10) {
        setImageOpacity(0);
        if (modelRefs.current[model].position.y > -0.5) {
          modelRefs.current[model].position.y -= 0.024;
        }
        if (modelRefs.current[model].position.y < -0.5) {
          modelRefs.current[model].position.y += 0.024;
        }
      }

      if (currentValue >= 10 && currentValue < 11) {
        setImageOpacity(0);
        if (modelRefs.current[model].position.y > -0.75) {
          modelRefs.current[model].position.y -= 0.024;
        }
        if (modelRefs.current[model].position.y < -0.75) {
          modelRefs.current[model].position.y += 0.024;
        }
      }
      if (currentValue >= 11 && currentValue < 12) {
        setImageOpacity(0);
        if (modelRefs.current[model].position.y > -1) {
          modelRefs.current[model].position.y -= 0.024;
        }
        if (modelRefs.current[model].position.y < -1) {
          modelRefs.current[model].position.y += 0.024;
        }
      }

      if (currentValue >= 12 && currentValue < 13) {
        setImageOpacity(0);
        if (modelRefs.current[model].position.y > -1.6) {
          modelRefs.current[model].position.y -= 0.024;
        }
        if (modelRefs.current[model].position.y < -1.6) {
          modelRefs.current[model].position.y += 0.024;
        }
      }

      if (currentValue >= 13 && currentValue <= 15) {
        setImageOpacity(0);
        if (modelRefs.current[model].position.y > -2.2) {
          modelRefs.current[model].position.y -= 0.024;
        }
        if (modelRefs.current[model].position.y < -2.2) {
          modelRefs.current[model].position.y += 0.024;
        }
      }

      if (currentValue >= 13 && currentValue <= 18) {
        setImageOpacity(0);
        if (
          modelRefs.current[model].position.y <= -2 &&
          modelRefs.current[model].position.y >= -2.18 &&
          !isInDestination.current
        ) {
          isInDestination.current = true;
          setTimeout(() => {
            setEnableScroll(true);
          }, 1000);
        }
      }

      if (currentValue < 12 && isInDestination.current)
        isInDestination.current = false;

      if (
        currentValue < 86 &&
        dropingModalPosition.current &&
        modelRefs.current[model].position.z !== dropingModalPosition.current
      ) {
        modelRefs.current[model].position.z = dropingModalPosition.current;
      }

      if (currentValue < prevScrollRef.current && currentValue > 13) {
        modelRefs.current[model].position.y = -2.1;
      }

      if (
        (modelRefs.current[model].position.y < -2.1 || currentValue > 13) &&
        isInDestination.current
      ) {
        modelRefs.current[model].position.z = -20;
      }
    }

    if (
      currentValue >= 12.7 &&
      !isInDestination.current &&
      currentValue < 17 &&
      direction !== "top" &&
      !showContactPage
    ) {
      scroll.current = 0.146;
      prevScrollRef.current = 14.3;
      setScrollTopToPercentage(14.6, el);
      if (enableScroll && !isInDestination.current) {
        setEnableScroll(false);
      }
    }
    if (
      isSection4 &&
      currentValue >= 6.85 &&
      currentValue < 16.7 &&
      currentValue > prevScrollRef.current &&
      !showContactPage
    ) {
      scroll.current = 6.9 / 100;
      setScrollTopToPercentage(7, el);
      setEnableScroll(false);
    }

    if (!enableScroll && isSection4) {
      setIsSection4(false);
      setTimeout(() => {
        setEnableScroll(true);
      }, 1000);
    }
    prevScrollRef.current = currentValue;
  });

  const pointerMovement = () => {
    if (!pointerMove) setPointerMove(false);
  };

  const handleTouchMove = (event) => {
    if (isPointerDown.current) {
      debounce(pointerMovement, 300);
      const deltaX = event.touches[0].clientX - pointerStartPosition.current.x;

      if (Math.abs(deltaX) > 5) {
        if (scroll.current > 6.2 / 100 && scroll.current < 7.7 / 100)
          setIsRotating(true);
        setPointerMove(true);
        const direction = deltaX > 0 ? "right" : "left";
        setMovementDirection(direction);
        pointerStartPosition.current.x = event.touches[0].clientX;
      }
    }
  };

  const handlePointerMove = (event) => {
    if (isPointerDown.current) {
      debounce(pointerMovement, 300);
      const deltaX = event.clientX - pointerStartPosition.current.x;

      if (Math.abs(deltaX) > 5) {
        if (scroll.current > 6.2 / 100 && scroll.current < 7.7 / 100)
          setIsRotating(true);
        setPointerMove(true);
        const direction = deltaX > 0 ? "right" : "left";
        setMovementDirection(direction);
        pointerStartPosition.current.x = event.clientX;
      }
    }
  };
  const handlePointerUp = (event) => {
    if (event.pointerType === "mouse") {
      window.removeEventListener("mouseup", handlePointerUp, false);
    }
    if (event.pointerType === "touch") {
      window.removeEventListener("touchend", handlePointerUp, false);
      window.removeEventListener("touchmove", handleTouchMove, false);
    }
    isPointerDown.current = false;
  };

  // Debounce function to limit event frequency
  function debounce(func, delay) {
    clearTimeout(debounceValue);
    debounceValue = setTimeout(func, delay);
  }

  const handlePointerDown = (event) => {
    if (event.pointerType === "mouse") {
      window.addEventListener("mouseup", handlePointerUp, false);
    }
    if (event.pointerType === "touch") {
      window.addEventListener("touchend", handlePointerUp, false);
      window.addEventListener("touchmove", handleTouchMove, false);
    }
    if (!isPointerDown.current && modelRefs.current[model].position.y >= 0) {
      isPointerDown.current = true;
      setScrollValue(scroll.current);
      setScrollValue((prev) => ({
        ...prev,
        scrollValue: scroll.current,
      }));
      // scroll.current = scrollValue;
      const containerHeight = el.clientHeight;
      const totalHeight = el.scrollHeight;
      // const scrollTopValue = el.scrollTop;
      const scrollPercentage =
        (el.scrollTop / (totalHeight - containerHeight)) * 100;
      const scrollTopValue =
        (scrollPercentage / 100) * (totalHeight - containerHeight);
      setScrollValue((prev) => ({
        ...prev,
        scrollPercentage: scrollTopValue,
      }));
      pointerStartPosition.current.x = event.clientX;
    }
  };

  const geometry = new BoxGeometry(43.8, 1.3, 0);
  const material = new MeshBasicMaterial({
    color: "white",
    transparent: true,
    opacity: 0,
  });

  return (
    <group onPointerMove={handlePointerMove}>
      <group
        onClick={() => {
          setRotateRight(true);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          // renderOrder={-100}
          transparent={true}
          path="assets/img/Arrow-Right.png"
          scale={arrowScale}
          position={rightArrowPosition}
        />
      </group>

      <group
        onClick={() => {
          setRotateLeft(true);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          // renderOrder={-100}
          transparent={true}
          path="assets/img/Arrow-Left.png"
          scale={arrowScale}
          position={leftArrowPosition}
        />
      </group>
      <mesh geometry={geometry} material={material} position={[2, -2, 4]} />
      <group
        ref={groupRef}
        position={position}
        scale={scale}
        rotation={[0, flowerRotation, 0]}
        onPointerDown={handlePointerDown}
      />

      <mesh
        renderOrder={-10}
        position={[buttonPosition[0], buttonPosition[1], 3.6]}
        onClick={() => {
          window.location.href = "https://catalogue.intodalai.com/";
        }}
      >
        <mesh
          // onPointerOver={handlePointerOver}
          // onPointerOut={handlePointerOut}
          onPointerOver={() => (
            imageOpacity === 1 && (document.body.style.cursor = "pointer"),
            setButtonHover(true)
          )}
          onPointerOut={() => (
            (document.body.style.cursor = "auto"), setButtonHover(false)
          )}
          scale={[buttonScale[0], buttonScale[1], 1.5]}
        >
          <PngLoader
            renderOrder={buttonHover && imageOpacity === 1 ? -1 : 0}
            transparent={true}
            path="assets/img/EXPLORE 3D CATALOGUE Button Blue - Semi Bold_4X.png"
            scale={[4.15, 0.65, 0.5]}
            opacity={imageOpacity}
          />
        </mesh>
        {/* <Text
              color="white"
              anchorX="center"
              anchorY="middle"
              fontSize={buttonTextSize}
              fontWeight="bold"
              onPointerOver={handlePointerOver}
              onPointerOut={handlePointerOut}
            >
              Explore 3D Catalogue
            </Text> */}
      </mesh>
    </group>
  );
};

export default Carousel3D;
