import { Point, Points } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import { AdditiveBlending, SRGBColorSpace, TextureLoader } from "three";

const particleColors = [
  "#fff",
  "#8afffb",
  "#43b5a0",
  "#491d88",
  "#5e5390",
  "#c4d201",
];

const RandomSizeParticlesSection4Top = ({ size = 230 }) => {
  const { width, height } = useThree((state) => state.viewport);
  const pointsRef = useRef();
  const texture = useLoader(TextureLoader, "/assets/img/circle.png");
  texture.colorSpace = SRGBColorSpace;
  const randomSizeOfPoints = [
    { particlesCount: 2200, pointSize: 0.018 },
    { particlesCount: 1800, pointSize: 0.0148 },
    { particlesCount: 1800, pointSize: 0.019 },
  ];
  const randomSize = [4.2, 4.1, 4.5, 4.3, 4.4];
  const pointsRefs = useRef(randomSizeOfPoints.map(() => React.createRef()));

  useFrame(() => {
    pointsRefs.current.forEach((pointsRef) => {
      const pointsGroup = pointsRef.current;
      if (pointsGroup) {
        pointsGroup.children.forEach((point, i) => {
          if (i % 2 === 0) {
            point.position.x += 0.0009; // Move right
          } else {
            point.position.x -= 0.0009; // Move left
          }
        });
      }
    });
  });

  return (
    <>
      {randomSizeOfPoints &&
        randomSizeOfPoints.map((point1, index) => (
          <Points
            ref={pointsRefs.current[index]}
            key={point1.particlesCount}
            limit={2200}
            position={[0, -0, -1]}
            renderOrder={-1}
          >
            <pointsMaterial
              size={point1.pointSize} // Adjust the size of the points
              vertexColors
              map={texture}
              alphaTest={0.5} // Adjust the alpha test value
              transparent={false}
              opacity={0.8} // Adjust the opacity of the points
              depthTest={true} // Disable depth testing for transparency
              blending={AdditiveBlending} // Use additive blending for a brighter appearance
            />
            {/*    0.5 * height + Math.random() ** 0.25 * height * -3, -3 is a count of the frames , ** 0.25 will do somehting like, points from top to bottom */}
            {Array.from({ length: size }).map((_, i) => (
              <Point
                key={i}
                // size={randomSize[Math.floor(Math.random() * randomSize.length)]}
                position={[
                  (0.5 - Math.random()) * width,
                  0.9 * height + Math.random() ** 0.5 * 4 * -2,
                  //   (Math.random() - 0.8) * height,
                  5,
                  // randomSize[Math.floor(Math.random() * randomSize.length)],
                ]}
                color={
                  particleColors[
                    Math.floor(Math.random() * (particleColors.length - 1))
                  ]
                }
              />
            ))}
          </Points>
        ))}
    </>
  );
};

export default RandomSizeParticlesSection4Top;
