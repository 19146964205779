export const setScrollTopToPercentage = (percentage, el) => {
  const containerHeight = el.clientHeight;
  const totalHeight = el.scrollHeight;

  // Calculate the scrollTop value as a percentage of the total height
  const scrollTopValue = (percentage / 100) * (totalHeight - containerHeight);

  // Set scrollTop to the calculated value
  el.scrollTop = scrollTopValue;
};
