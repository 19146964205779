import React, { useContext, useEffect, useRef, useState } from "react";
import Carousel3D from "./carousel.component";
import BoxModel from "./boxModel.component";
import PacketCarousel3D from "./packetCarousel.component";
import { useFrame, useThree } from "@react-three/fiber";
import FixedTextComponent from "../fixedText.component";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import PngLoader from "../pngLoader.component";
import { useScroll } from "../ScrollControls";
import Section7Box from "./boxWingModel.component";
import { Section6Context } from "../../context/section6.context";

const Section5 = () => {
  const [direction, setDirection] = useState();
  const [isPacketInDestination, setIsPacketInDestination] = useState(false);
  const [isPacketInitialPosition, setIsPacketInitialPosition] = useState(false);
  const isInDestination = useRef(false);
  const [font, setFont] = useState(null);
  const [neulisAltFont, setNeulisAltFont] = useState(null);
  const [helvetikerFont, setHelvetikerFont] = useState(null);
  const [buttonPosition, setButtonPosition] = useState();
  const [buttonScale, setButtonScale] = useState();

  const { scroll } = useScroll();

  const { width } = useThree((state) => state.viewport);

  const { setShowBlueLogo } = useContext(Section6Context);

  // for flowers
  const [flowersPosition, setFlowersPosition] = useState();
  const [circleRadius, setCircleRadius] = useState();
  const [flowerRotation, setFlowerRotation] = useState();
  const [modelScales, setModelScales] = useState();
  const [leftArrowPosition, setLeftArrowPosition] = useState();
  const [arrowScale, setArrowScale] = useState();
  const [rightArrowPosition, setRightArrowPosition] = useState();

  // for packets
  const [packetRotation, setPacketRotation] = useState();
  const [packetPosition, setPacketPosition] = useState();
  const [packetScale, setPacketScale] = useState();
  const [packetYRotation, setPacketYRotation] = useState();
  const [packetrightArrowPosition, setPacketRightArrowPosition] = useState();
  const [packetleftArrowPosition, setPacketLeftArrowPosition] = useState();

  // for box
  const [boxScale, setBoxScale] = useState();
  const [boxPosition, setBoxPosition] = useState();
  const [emailImagePosition, setEmailImagePosition] = useState();
  const [instaImagePosition, setInstaImagePosition] = useState();
  const [mobileImagePosition, setMobileImagePosition] = useState();
  const [emailImageScale, setEmailImageScale] = useState();
  const [instaImageScale, setInstaImageScale] = useState();
  const [mobileImageScale, setMobileImageScale] = useState();
  const [downArrowPosition, setDownArrowPosition] = useState();
  const [downArrowScale, setDownArrowScale] = useState();
  const [contactButtonScale, setContactButtonScale] = useState();
  const [contactButtonPosition, setContactButtonPosition] = useState();

  useEffect(() => {
    if (width < 5.67) {
      // section 2
      section2TextDetails.current.position = [-width * 0.21, -15.4, 2.6];
      section2TextDetails.current.subTextPosition = [
        -width * 0.21,
        -15.55,
        2.6,
      ];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.21,
        -15.8,
        2.6,
      ];
      section2TextDetails.current.textScale = [0.0028, -0.0028, 0.005];
      section2TextDetails.current.subTextSize = 9.9;
      section2TextDetails.current.bodyTextSize = 8;
      section2TextDetails.current.subText =
        "Specialized in distribution of high \nquality CBD products.";
      section2TextDetails.current.bodyText =
        "Since 2021 we have been trading flowers \ncreating value for partners and businesses \nby bridging the European Cannabis  \nIndustry. We believe in strong relationships \nto develop a fair ecosystem based \non trust and collaboration.";

      setFlowersPosition([0, -2.29]);
      setCircleRadius(0.2);
      setModelScales([
        [0.017, 0.023, 0.01],
        [0.022, 0.032, 0.045],
        [0.032, 0.038, 0.035],
      ]);
      setFlowerRotation(0.5);
      setPacketPosition([0, -4.32]);
      setPacketScale([0.038, 0.04, 0.01]);
      setPacketRotation(-0.02);
      setPacketYRotation(-0.012);
      setBoxScale([0.63, 0.63, 0.1]);
      setBoxPosition([0, -5.71, 4.5]);
      setEmailImagePosition([0, -0.007, 0]);
      setInstaImagePosition([0.001, -0.03, 0]);
      setMobileImagePosition([0.02, 0.022, 0]);
      setEmailImageScale([0.31, 0.1, 1]);
      setInstaImageScale([0.32, 0.12, 1]);
      setMobileImageScale([0.28, 0.13, 1]);
      setDownArrowPosition([0, -4.85, 4.1]);
      setDownArrowScale([0.2, 0.23, 0.1]);
      setLeftArrowPosition([-0.24, -1.76, 2]);
      setRightArrowPosition([0.24, -1.76, 2]);
      setArrowScale([0.23, 0.23, 1]);
      setPacketLeftArrowPosition([-0.24, -3.42, 2]);
      setPacketRightArrowPosition([0.24, -3.42, 2]);
      setButtonPosition([0, -2.635]);
      setButtonScale([0.03, 0.2]);
      setContactButtonPosition([-0.066, -0.046, 0]);
      setContactButtonScale([0.25, 0.13, 1]);

      // section 6
      section6TextDetails.current.position = [-width * 0.12, -1.3, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.12, -1.4, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.12,
        -1.48,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.002, -0.002, 0.005];
      section6TextDetails.current.subTextSize = 5.7;
      section6TextDetails.current.bodyTextSize = 4.2;
      section6TextDetails.current.text = "DRIVEN BY \nPASSION";
      section6TextDetails.current.bodyText =
        "We curate the finest selections of CBD products \nprioritizing quality at every step of the process. \nFrom seed to harvest, our hemp flowers are \norganically grown with different setups and \nfeatures and undergo rigorous testing by \nthird-party laboratories. Transparency and \nintegrity are at the core of everything we do.";
      // section 7
      section7TextDetails.current.position = [-width * 0.12, -3.4, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.12, -3.5, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.12,
        -3.62,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0017, -0.0017, 0.005];
      section7TextDetails.current.subTextSize = 5.1;
      section7TextDetails.current.bodyTextSize = 4.1;
      section7TextDetails.current.bodyText =
        "Since 2021 we set quality control procedures, filling and \npacking services and THC reduction treatments for all \nproducts we distribute. Dalai is consistently becoming the \nstandard for high quality products and assurance on \nprocedures and services.";
    } else if (width < 7.7 && width >= 5.67) {
      // section 2
      section2TextDetails.current.position = [-width * 0.21, -15.6, 2.6];
      section2TextDetails.current.subTextPosition = [
        -width * 0.21,
        -15.75,
        2.6,
      ];
      section2TextDetails.current.bodyTextPosition = [-width * 0.21, -16, 2.6];
      section2TextDetails.current.textScale = [0.003, -0.003, 0.01];
      section2TextDetails.current.subTextSize = 10.7;
      section2TextDetails.current.bodyTextSize = 7.7;
      section2TextDetails.current.subText =
        "Specialized in distribution of high \nquality CBD products.";
      section2TextDetails.current.bodyText =
        "Since 2021 we have been trading flowers \ncreating value for partners and businesses \nby bridging the European Cannabis  \nIndustry. We believe in strong relationships \nto develop a fair ecosystem based \non trust and collaboration.";

      setFlowersPosition([0, -2.25]);
      setCircleRadius(0.25);
      setFlowerRotation(0.5);
      setModelScales([
        [0.022, 0.029, 0.015],
        [0.032, 0.042, 0.05],
        [0.042, 0.047, 0.04],
      ]);
      setPacketPosition([0, -4.3]);
      setPacketScale([0.042, 0.044, 0.01]);
      setPacketRotation(-0.01);
      setPacketYRotation(0.05);
      setBoxScale([0.72, 0.74, 0.1]);
      setBoxPosition([0, -5.68, 4.5]);
      setEmailImagePosition([0, -0.007, 0]);
      setInstaImagePosition([0.001, -0.04, 0]);
      setMobileImagePosition([0.026, 0.022, 0]);
      setEmailImageScale([0.35, 0.17, 1]);
      setInstaImageScale([0.37, 0.17, 1]);
      setMobileImageScale([0.32, 0.17, 1]);
      setDownArrowPosition([0, -4.85, 4.1]);
      setDownArrowScale([0.2, 0.23, 0.1]);
      setLeftArrowPosition([-0.4, -1.68, 2]);
      setRightArrowPosition([0.4, -1.68, 2]);
      setArrowScale([0.25, 0.25, 1]);

      // section 6
      section6TextDetails.current.position = [-width * 0.13, -1.3, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, -1.4, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -1.48,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.0025, -0.0025, 0.01];
      section6TextDetails.current.subTextSize = 5.8;
      section6TextDetails.current.bodyTextSize = 4.5;

      // section 7
      section7TextDetails.current.position = [-width * 0.13, -3.4, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, -3.5, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -3.62,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0025, -0.0025, 0.01];
      section7TextDetails.current.subTextSize = 5.8;
      section7TextDetails.current.bodyTextSize = 4.5;
      setPacketLeftArrowPosition([-0.4, -3.38, 2]);
      setPacketRightArrowPosition([0.38, -3.38, 2]);
      setButtonPosition([0, -2.68]);
      setButtonScale([0.04, 0.3]);
      setContactButtonPosition([-0.086, -0.065, 0]);
      setContactButtonScale([0.29, 0.15, 1]);
    } else if (width >= 7.7 && width < 11.34) {
      setFlowersPosition([0.4, -2.12]);
      setCircleRadius(0.35);
      setModelScales([
        [0.022, 0.032, 0.012],
        [0.04, 0.048, 0.055],
        [0.05, 0.052, 0.05],
      ]);
      setFlowerRotation(0.15);
      setPacketPosition([0.33, -4.16]);
      setPacketScale([0.052, 0.052, 0.012]);
      setPacketRotation(-0.33);
      setPacketYRotation(0.3);
      setBoxScale([0.8, 0.8, 0.1]);
      setBoxPosition([width * 0.018, -5.6, 4.5]);
      setEmailImagePosition([-0.01, -0.01, 0]);
      setInstaImagePosition([0, -0.05, 0]);
      setMobileImagePosition([0.03, 0.034, 0]);
      setEmailImageScale([0.37, 0.17, 1]);
      setInstaImageScale([0.4, 0.17, 1]);
      setMobileImageScale([0.35, 0.17, 1]);
      setDownArrowPosition([0, -4.672, 4.1]);
      setDownArrowScale([0.25, 0.28, 0.1]);
      setLeftArrowPosition([-0.05, -1.56, 2]);
      setRightArrowPosition([0.78, -1.56, 2]);
      setArrowScale([0.25, 0.25, 1]);
      setPacketLeftArrowPosition([-0.1, -3.2, 2]);
      setPacketRightArrowPosition([0.84, -3.2, 2]);
      setButtonPosition([0.58, -2.5]);
      setButtonScale([0.04, 0.3]);
      setContactButtonPosition([-0.11, -0.077, 0]);
      setContactButtonScale([0.315, 0.15, 1]);
    } else if (width >= 11.34 && width <= 14.5) {
      setFlowersPosition([0.46, -2.12]);
      setCircleRadius(0.32);
      setFlowerRotation(0.07);
      setPacketPosition([0.42, -4.2]);
      setPacketRotation(-0.41);
      setPacketYRotation(0.44);
      setBoxScale([0.86, 0.86, 0.1]);
      setBoxPosition([0.2, -5.6, 4.5]);
      setEmailImagePosition([-0.01, -0.01, 0]);
      setInstaImagePosition([0, -0.057, 0]);
      setMobileImagePosition([0.037, 0.036, 0]);
      setEmailImageScale([0.4, 0.17, 1]);
      setInstaImageScale([0.42, 0.17, 1]);
      setMobileImageScale([0.37, 0.17, 1]);
      setDownArrowPosition([0, -4.672, 4.1]);
      setDownArrowScale([0.25, 0.28, 0.1]);
      setModelScales([
        [0.022, 0.032, 0.012],
        [0.038, 0.046, 0.055],
        [0.046, 0.05, 0.05],
      ]);
      setPacketScale([0.045, 0.045, 0.02]);
      setLeftArrowPosition([-0.05, -1.5, 2]);
      setRightArrowPosition([0.88, -1.5, 2]);
      setPacketLeftArrowPosition([-0.03, -3.13, 2]);
      setPacketRightArrowPosition([0.8, -3.13, 2]);
      setArrowScale([0.3, 0.3, 1]);
      setButtonPosition([0.67, -2.45]);
      setButtonScale([0.04, 0.3]);
      setContactButtonPosition([-0.12, -0.09, 0]);
      setContactButtonScale([0.36, 0.17, 1]);
    } else {
      setFlowersPosition([0.5, -2.1]);
      setCircleRadius(0.4);
      setFlowerRotation(0.07);
      setPacketPosition([0.45, -4.2]);
      setPacketRotation(-0.435);
      setPacketYRotation(0.44);
      setBoxScale([1, 1, 0.1]);
      setBoxPosition([0.2, -5.6, 4.5]);
      setEmailImagePosition([0, -0.02, 0]);
      setInstaImagePosition([0, -0.07, 0]);
      setMobileImagePosition([0.045, 0.04, 0]);
      setEmailImageScale([0.49, 0.17, 1]);
      setInstaImageScale([0.49, 0.17, 1]);
      setMobileImageScale([0.42, 0.17, 1]);
      setDownArrowPosition([0, -4.672, 4.1]);
      setDownArrowScale([0.25, 0.28, 0.1]);
      setModelScales([
        [0.032, 0.042, 0.015],
        [0.044, 0.052, 0.052],
        [0.054, 0.056, 0.05],
      ]);
      setPacketScale([0.066, 0.067, 0.00001]);
      // setPacketScale([2.6, 3, 0.02]);
      setLeftArrowPosition([-0.05, -1.48, 2]);
      setRightArrowPosition([1.03, -1.48, 2]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setArrowScale([0.27, 0.27, 1]);
      setButtonPosition([0.75, -2.5]);
      setButtonScale([0.04, 0.3]);
      setContactButtonPosition([-0.155, -0.12, 0]);
      setContactButtonScale([0.4, 0.17, 1]);
    }
  }, [width]);

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "auto";
  };

  const handleClick = () => {
    scroll.current = 0.35;

    // Create and dispatch the touchend event
    const touchEndEvent = new Event("touchend");
    window.dispatchEvent(touchEndEvent);
  };

  useFrame(() => {
    const currentScroll = scroll.current * 100;
    if (currentScroll > 6.5 && currentScroll <= 26) {
      setShowBlueLogo(true);
    } else {
      setShowBlueLogo(false);
    }
  });

  // for loading fontTexture
  useEffect(() => {
    const loadFontTexture = async () => {
      try {
        const loader = new FontLoader();

        loader.load("/font/manifold.json", function (font) {
          setFont(font);
        });

        loader.load("/font/Neulis Alt Light_Light.json", function (font) {
          setNeulisAltFont(font);
        });

        loader.load(
          "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
          function (font) {
            setHelvetikerFont(font);
          }
        );
      } catch (error) {
        console.error("Error loading font texture:", error);
      }
    };
    loadFontTexture();
  }, []);

  // section 6 details
  const section6TextDetails = useRef({
    isAdded: false,
    text: "DRIVEN BY PASSION",
    align: "center",
    pointSize: 1.3,
    subText: "We only commit to excellence.",
    currentMin: 4,
    name: "section6",
    currentMax: 7.5,
    bodyText:
      "We curate the finest selections of CBD products prioritizing quality at \nevery step of the process. From seed to harvest, our hemp flowers \nare organically grown with different setups and features and undergo \nrigorous testing by third-party laboratories. Transparency and integrity \nare at the core of everything we do.",
  });

  // section 2 details
  const section2TextDetails = useRef({
    isAdded: false,
    pointSize: 2,
    text: "DRIVEN BY GROWTH",
    align: "center",
    subText: "Specialized in distribution of high \nquality CBD products.",
    bodyText:
      "Since 2021 we have been trading flowers creating value \nfor partners and businesses by bridging the European \nCannabis Industry. We believe in strong relationships to \ndevelop a fair ecosystem based on trust and collaboration.",
    currentMin: 58.5,
    name: "section2",
    currentMax: 61.55,
  });

  // section 7 details
  let section7TextDetails = useRef({
    isAdded: false,
    text: "WE ARE YOUR\nTRUSTED SUPPLIER",
    align: "center",
    pointSize: 1.3,
    subText: "Dalai offers complementary services to ease\nyour operations.",
    currentMin: 14.2,
    currentMax: 17.5,
    name: "section7",
    bodyText:
      "Since 2021 we set quality control procedures, filling and \npacking services and THC reduction treatments for all \nproducts we distribute. Dalai is consistently becoming the \nstandard for high quality products and assurance on \nprocedures and services.",
  });

  return (
    <>
      {packetPosition &&
        packetRotation &&
        packetScale &&
        circleRadius &&
        packetYRotation && (
          <PacketCarousel3D
            direction={direction}
            setDirection={setDirection}
            isPacketInitialPosition={isPacketInitialPosition}
            setIsPacketInDestination={setIsPacketInDestination}
            isPacketInDestination={isPacketInDestination}
            setIsPacketInitialPosition={setIsPacketInitialPosition}
            isInDestination={isInDestination}
            position={[packetPosition[0], packetPosition[1], 4.1]}
            packetRotation={packetRotation}
            packetScale={packetScale}
            radius={circleRadius}
            packetYRotation={packetYRotation}
            arrowScale={arrowScale}
            packetleftArrowPosition={packetleftArrowPosition}
            packetrightArrowPosition={packetrightArrowPosition}
          />
        )}
      {flowerRotation && circleRadius && modelScales && flowersPosition && (
        <Carousel3D
          direction={direction}
          setDirection={setDirection}
          isPacketInitialPosition={isPacketInitialPosition}
          isInDestination={isInDestination}
          position={[flowersPosition[0], flowersPosition[1], 4]}
          radius={circleRadius}
          flowerRotation={flowerRotation}
          modelScales={modelScales}
          leftArrowPosition={leftArrowPosition}
          rightArrowPosition={rightArrowPosition}
          arrowScale={arrowScale}
          buttonPosition={buttonPosition}
          buttonScale={buttonScale}
        />
      )}
      {font && neulisAltFont && helvetikerFont && (
        <>
          <FixedTextComponent
            sectionName={section6TextDetails}
            font={font}
            font2={helvetikerFont}
            color={0x7888b7}
          />

          <FixedTextComponent
            sectionName={section2TextDetails}
            font={font}
            font2={neulisAltFont}
            color={0xffffff}
          />

          <FixedTextComponent
            sectionName={section7TextDetails}
            font={font}
            font2={helvetikerFont}
            isInDestination={isInDestination}
            color={0x7888b7}
          />
        </>
      )}
      <Section7Box
        direction={direction}
        isPacketInDestination={isPacketInDestination}
        boxScale={boxScale}
        boxPosition={boxPosition}
        emailImagePosition={emailImagePosition}
        instaImagePosition={instaImagePosition}
        mobileImagePosition={mobileImagePosition}
        emailImageScale={emailImageScale}
        instaImageScale={instaImageScale}
        mobileImageScale={mobileImageScale}
        contactButtonScale={contactButtonScale}
        contactButtonPosition={contactButtonPosition}
      />

      <group
        onClick={handleClick}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          transparent={true}
          renderOrder={-10}
          path="assets/img/Down-Arrow.png"
          position={downArrowPosition}
          scale={downArrowScale}
        />
      </group>
      {/* {boxPosition &&
        boxScale &&
        detailImage &&
        detailImagePosition &&
        detailImageScale && (
          <>
            <BoxModel
              modelPath="/assets/3d-Models/Box_big.glb"
              scale={boxScale}
              position={[boxPosition[0], boxPosition[1], boxPosition[2]]}
              rotating={true}
              animate={false}
              direction={direction}
              isPacketInDestination={isPacketInDestination}
              setDirection={setDirection}
              detailImage={detailImage}
              detailImagePosition={detailImagePosition}
              detailImageScale={detailImageScale}
            />
            <BoxWingModel
              modelPath="/assets/3d-Models/Box_wing.glb"
              scale={boxScale}
              position={[boxPosition[0], boxPosition[1], boxPosition[2]]}
              rotating={true}
              animate={false}
              direction={direction}
              isPacketInDestination={isPacketInDestination}
              setDirection={setDirection}
            />
          </>
        )} */}
    </>
  );
};

export default Section5;
