import React, { createContext, useCallback, useEffect, useState } from "react";

const defaultValues = {
  showContactPage: false,
  setShowContactPage: () => {},
};
export const Section8Context = createContext(defaultValues);

export const Section8ContextProvider = ({ children }) => {
  const [showContactPage, setShowContactPage] = useState(false);

  const value = {
    showContactPage,
    setShowContactPage,
  };

  return (
    <Section8Context.Provider value={value}>
      {children}
    </Section8Context.Provider>
  );
};
