import React, { useRef, useEffect, useState, useContext } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { useFrame, useLoader } from "@react-three/fiber";
import { BoxGeometry, MeshBasicMaterial, Mesh, Vector3 } from "three";
import { useScroll } from "../ScrollControls";
import { ScrollContext } from "../../context/scroll.context";
import { radToDeg } from "three/src/math/MathUtils";
import { Section8Context } from "../../context/section8.context";
import { LoadingContext } from "../../context/loading.context";
import gsap from "gsap";
import * as THREE from "three";
import PngLoader from "../pngLoader.component";

const PacketCarousel3D = ({
  position,
  scale = [1, 1, 1],
  radius,
  direction,
  setDirection,
  setIsPacketInitialPosition,
  setIsPacketInDestination,
  isPacketInDestination,
  isInDestination,
  packetRotation,
  packetScale,
  packetYRotation,
  packetrightArrowPosition,
  packetleftArrowPosition,
  arrowScale,
}) => {
  const [rotating, setIsRotating] = useState(false);
  const [pointerMove, setPointerMove] = useState(false);
  const [movementDirection, setMovementDirection] = useState("");
  const [scrollValue, setScrollValue] = useState({
    scrollValue: 0,
    scrollPercentage: 0,
  });
  const [lastY, setLastY] = useState();
  const [model, setModel] = useState(0);
  const groupRef = useRef();
  const isPointerDown = useRef(false);
  const dropingModalPosition = useRef(null);
  const pointerStartPosition = useRef({ x: 0 });
  const prevScrollRef = useRef(null);
  const modelRefs = useRef([]);
  const speedRef = useRef(0.009);
  const { setLoading } = useContext(LoadingContext);
  const { setEnableScroll } = useContext(ScrollContext);
  const { showContactPage } = useContext(Section8Context);
  const { scroll, el } = useScroll();
  const [rotationCount, setRotationCount] = useState(0);
  const [rotateLeft, setRotateLeft] = useState(false);
  const [rotateRight, setRotateRight] = useState(false);

  const rotationSpeed = 0.18;
  let debounceValue = null;
  const rotationFactor = 0.1;

  const modelPaths = [
    "/assets/3d-Models/Pouch_Daisy_Gems.glb",
    "/assets/3d-Models/Pouch_Daisy_Purple.glb",
    "/assets/3d-Models/Pouch_Daisy_Classic.glb",
  ];

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "auto";
  };

  const models = useLoader(GLTFLoader, modelPaths);

  useEffect(() => {
    setLastY(radToDeg(packetRotation).toFixed(0));
  }, [packetRotation]);

  useEffect(() => {
    if (radius) {
      const angleIncrement = (2 * Math.PI) / modelPaths.length;
      models.forEach((gltf, index) => {
        const model = gltf.scene;
        model.scale.set(...packetScale);
        model.position.set(
          index === 0
            ? 0
            : ((radius * Math.sqrt(3)) / 2) * (index === 1 ? -1 : 1),
          0,
          index === 0 ? radius : index === 1 ? -radius / 2 : -radius / 2
        );
        const hemisphereLight = new THREE.HemisphereLight(
          0xffffff,
          0x000000,
          0.3
        );
        hemisphereLight.position.set(0, 1, 0); // Adjust position according to your scene
        groupRef.current.add(hemisphereLight);
        model.rotation.y = packetYRotation;
        modelRefs.current[index] = model;
        groupRef.current.add(model);
        if (index === 0) {
          setLoading("package", true);
        }
      });

      // models.forEach((modelPath, index) => {
      //   const angle = index * angleIncrement + (2 * Math.PI) / 3;
      //   const x = modelPath.position.x;
      //   const y = modelPath.position.y;
      //   const z = modelPath.position.z;
      //   const loader = new GLTFLoader();
      //   loader.load(modelPath.path, (gltf) => {
      //     const model = gltf.scene;
      //     model.scale.set(...packetScale);
      //     model.position.set(x, y, z);
      //     model.rotation.y = packetYRotation;
      //     modelRefs.current[index] = model;
      //     groupRef.current.add(model);
      //     if (index === 0) {
      //       setLoading("package", true);
      //     }
      //   });
      // });
    }
  }, [radius, packetYRotation]);

  useEffect(() => {
    if (
      modelRefs.current &&
      modelRefs.current.length &&
      modelRefs.current[model] &&
      modelRefs.current[model].position &&
      modelRefs.current[model].position.z > -2
    ) {
      dropingModalPosition.current = modelRefs.current[model].position.z;
    }
  }, [
    model,
    modelRefs.current,
    modelRefs.current.length,
    modelRefs.current[model],
  ]);

  useFrame(({ mouse }) => {
    const currentValue = scroll.current * 10 * 10;

    if (
      (rotateLeft || rotateRight) &&
      radToDeg(groupRef.current.rotation.y).toFixed(0) <=
        parseFloat(lastY) + 120 &&
      currentValue > 14.5 &&
      currentValue < 15.5 &&
      isInDestination.current &&
      modelRefs.current[model].position.y >= 0
    ) {
      setEnableScroll(false);
      let targetY;
      if (rotateLeft) {
        targetY = groupRef.current.rotation.y - rotationSpeed; // Rotate left
      } else {
        targetY = groupRef.current.rotation.y + rotationSpeed; // Rotate right
      }

      modelRefs.current.forEach((model) => {
        if (rotateLeft) {
          model.rotation.y +=
            navigator && navigator.platform.toLowerCase().includes("mac")
              ? 0.0162
              : 0.036;
        }
        if (rotateRight) {
          model.rotation.y -=
            navigator && navigator.platform.toLowerCase().includes("mac")
              ? 0.0162
              : 0.036;
        }
      });

      groupRef.current.rotation.y = THREE.MathUtils.lerp(
        groupRef.current.rotation.y,
        targetY,
        navigator && navigator.platform.toLowerCase().includes("mac")
          ? 0.09
          : 0.2
      );

      const newRotation =
        ((radToDeg(groupRef.current.rotation.y).toFixed(2) / 120) * 120) % 120;
      if (
        ((newRotation >= radToDeg(packetRotation) ||
          newRotation >= radToDeg(packetRotation) - 1) &&
          newRotation <= radToDeg(packetRotation) + 1) ||
        ((newRotation > radToDeg(packetRotation) + 120 ||
          newRotation > radToDeg(packetRotation) + 119) &&
          newRotation < radToDeg(packetRotation) + 121)
      ) {
        setRotationCount(
          Math.abs((radToDeg(groupRef.current.rotation.y) / 120).toFixed(0))
        );
        setLastY(radToDeg(groupRef.current.rotation.y).toFixed(0));
        setRotateLeft(false);
        setRotateRight(false);
        setEnableScroll(true);
      }
    }

    if (
      currentValue < prevScrollRef.current &&
      prevScrollRef.current - currentValue > 0.5 &&
      direction !== "top"
    ) {
      setDirection("top");
    }

    if (
      currentValue > prevScrollRef.current &&
      currentValue - prevScrollRef.current > 0.5 &&
      direction !== "down"
    ) {
      setDirection("down");
    }
    if (
      (isPointerDown &&
        rotating &&
        currentValue > 14.5 &&
        currentValue < 15.5 &&
        isInDestination.current &&
        modelRefs.current[model].position.y >= 0) ||
      (!isPointerDown.current &&
        rotating &&
        isInDestination.current &&
        modelRefs.current[model].position.y >= 0)
    ) {
      setEnableScroll(false);
      scroll.current = scrollValue.scrollValue;
      el.scrollTop = scrollValue.scrollPercentage;
      const rotationDelta = rotationSpeed * rotationFactor;

      // LERP
      let targetY =
        groupRef.current.rotation.y +
        (movementDirection === "left" ? -rotationSpeed : rotationSpeed);

      groupRef.current.rotation.y = THREE.MathUtils.lerp(
        groupRef.current.rotation.y,
        targetY,
        navigator && navigator.platform.toLowerCase().includes("mac")
          ? 0.09
          : 0.2
      );

      // gsap.to(groupRef.current.rotation, {
      //   duration: 0.01, // Animation duration in seconds
      //   y: `+=${movementDirection === "left" ? -0.029 : 0.029}`,
      //   ease: "circ.out", // Easing function (optional)
      // });

      // groupRef.current.rotation.y +=
      //   movementDirection === "left" ? -rotationDelta : rotationDelta;

      modelRefs.current.forEach((model) => {
        model.rotation.y +=
          movementDirection === "left"
            ? navigator && navigator.platform.toLowerCase().includes("mac")
              ? 0.0162
              : 0.036
            : navigator && navigator.platform.toLowerCase().includes("mac")
            ? -0.0162
            : -0.036;
      });

      const newRotation =
        ((radToDeg(groupRef.current.rotation.y).toFixed(0) / 120) * 120) % 120;

      if (
        rotating &&
        (!isPointerDown.current || !pointerMove) &&
        (((newRotation >= radToDeg(packetRotation) ||
          newRotation >= radToDeg(packetRotation) - 1) &&
          newRotation <= radToDeg(packetRotation) + 1) ||
          ((newRotation > radToDeg(packetRotation) + 120 ||
            newRotation > radToDeg(packetRotation) + 119) &&
            newRotation < radToDeg(packetRotation) + 121))
      ) {
        setRotationCount(
          Math.abs((radToDeg(groupRef.current.rotation.y) / 120).toFixed(0))
        );
        setIsRotating(false);
        setLastY(radToDeg(groupRef.current.rotation.y).toFixed(0));
        setPointerMove(false);
        setEnableScroll(true);
      }
    }

    if (modelRefs.current[model]) {
      if (
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0)) % 360 === 0 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0)) % 360 ===
          359 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0)) % 360 ===
          -359 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0)) % 360 ===
          -1 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0)) % 360 === 1
      ) {
        setModel(0);
      }

      if (
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 120) %
          360 ===
          0 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 120) %
          360 ===
          359 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 120) %
          360 ===
          -1 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 120) %
          360 ===
          1 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 120) %
          360 ===
          -359
      ) {
        setModel(1);
      }
      if (
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 240) %
          360 ===
          0 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 240) %
          360 ===
          -1 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 240) %
          360 ===
          359 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 240) %
          360 ===
          -359 ||
        (parseFloat(lastY) - radToDeg(packetRotation).toFixed(0) - 240) %
          360 ===
          1
      ) {
        setModel(2);
      }
      if (
        !rotating &&
        !rotateLeft &&
        !rotateRight &&
        rotationCount % 3 === 0 &&
        model === 0
      ) {
        modelRefs.current.forEach((model) => {
          model.rotation.y = packetYRotation;
        });
      }

      if (currentValue > 12.7 && currentValue < 16 && !showContactPage) {
        scroll.current = 14.6 / 100;
      }

      // if (currentValue < 15.8) {
      //   if (modelRefs.current[model].position.y <= 0) {
      //     modelRefs.current[model].position.y += 0.02;
      //   }
      // }

      // if (currentValue >= 13 && currentValue < 16) {
      //   gsap.to(modelRefs.current[model].position, {
      //     y: 0.01,
      //     duration: 1.3,
      //     ease: "power2.out",
      //   });
      // }

      // if (currentValue >= 16 && currentValue < 18) {
      //   gsap.to(modelRefs.current[model].position, {
      //     y: -0.7,
      //     duration: 1.3,
      //     ease: "power2.out",
      //   });
      // }

      // if (currentValue >= 18 && currentValue < 20) {
      //   gsap.to(modelRefs.current[model].position, {
      //     y: -1,
      //     duration: 1.3,
      //     ease: "power2.out",
      //   });
      // }

      // if (currentValue >= 20 && currentValue < 22) {
      //   gsap.to(modelRefs.current[model].position, {
      //     y: -1.61,
      //     duration: 1.3,
      //     ease: "power2.out",
      //   });
      // }

      if (currentValue >= 13 && currentValue < 16) {
        if (modelRefs.current[model].position.y <= 0) {
          modelRefs.current[model].position.y += 0.02;
        }
        if (modelRefs.current[model].position.y <= 0) {
          modelRefs.current[model].position.y += 0.02;
        }
      }

      if (currentValue >= 16 && currentValue < 17) {
        if (modelRefs.current[model].position.y > -0.5) {
          modelRefs.current[model].position.y -= 0.02;
        }

        if (modelRefs.current[model].position.y < -0.5) {
          modelRefs.current[model].position.y += 0.02;
        }
      }

      if (currentValue >= 17 && currentValue < 18) {
        if (modelRefs.current[model].position.y > -0.8) {
          modelRefs.current[model].position.y -= 0.02;
        }
        if (modelRefs.current[model].position.y < -0.8) {
          modelRefs.current[model].position.y += 0.02;
        }
      }

      if (currentValue >= 18 && currentValue < 19) {
        if (modelRefs.current[model].position.y > -1) {
          modelRefs.current[model].position.y -= 0.02;
        }
        if (modelRefs.current[model].position.y < -1.1) {
          modelRefs.current[model].position.y += 0.02;
        }
      }

      if (currentValue >= 19 && currentValue < 22) {
        if (modelRefs.current[model].position.y > -1.6) {
          modelRefs.current[model].position.y -= 0.02;
        }
        if (modelRefs.current[model].position.y < -1.6) {
          modelRefs.current[model].position.y += 0.02;
        }
      }

      if (currentValue < 19.2 && isPacketInDestination)
        setIsPacketInDestination(false);

      if (currentValue < 13) {
        modelRefs.current[model].position.y = 0;
      }
      if (currentValue > 14 && currentValue < 23) {
        if (
          modelRefs.current[model].position.y <= -1.5 &&
          modelRefs.current[model].position.y >= -1.6
        ) {
          setIsPacketInDestination(true);
          setEnableScroll(true);
        }
      }

      if (modelRefs.current[model].position.y === 0) {
        setIsPacketInitialPosition(true);
      }
      if (modelRefs.current[model].position.y !== 0) {
        setIsPacketInitialPosition(false);
      }

      if (currentValue < prevScrollRef.current && currentValue > 21) {
        modelRefs.current[model].position.y = -1.6;
      }

      if (
        currentValue < 20.6 &&
        dropingModalPosition.current &&
        modelRefs.current[model].position.z !== dropingModalPosition.current
      ) {
        modelRefs.current[model].position.z = dropingModalPosition.current;
      }

      if (
        (modelRefs.current[model].position.y <= -1.59 || currentValue > 20.6) &&
        isPacketInDestination
      ) {
        modelRefs.current[model].position.z = -20;
      }
    }
    prevScrollRef.current = currentValue;
  });

  const pointerMovement = () => {
    if (!pointerMove) setPointerMove(false);
  };

  const handleTouchMove = (event) => {
    if (isPointerDown.current) {
      debounce(pointerMovement, 300);
      const deltaX = event.touches[0].clientX - pointerStartPosition.current.x;

      if (Math.abs(deltaX) > 5) {
        if (prevScrollRef.current > 15.8 && prevScrollRef.current < 15.5)
          setIsRotating(true);
        setPointerMove(true);
        const direction = deltaX > 0 ? "right" : "left";
        setMovementDirection(direction);
        pointerStartPosition.current.x = event.touches[0].clientX;
      }
    }
  };
  const handlePointerMove = (event) => {
    if (isPointerDown.current) {
      debounce(pointerMovement, 300);
      const deltaX = event.clientX - pointerStartPosition.current.x;

      if (Math.abs(deltaX) > 5) {
        if (prevScrollRef.current > 14.5 && prevScrollRef.current < 15.5)
          setIsRotating(true);
        setPointerMove(true);
        const direction = deltaX > 0 ? "right" : "left";
        setMovementDirection(direction);
        pointerStartPosition.current.x = event.clientX;
      }
    }
  };

  const handlePointerUp = (event) => {
    if (event.pointerType === "mouse") {
      window.removeEventListener("mouseup", handlePointerUp, false);
    }
    if (event.pointerType === "touch") {
      window.removeEventListener("touchend", handlePointerUp, false);
      window.removeEventListener("touchmove", handleTouchMove, false);
    }
    isPointerDown.current = false;
  };

  const handlePointerDown = (event) => {
    if (event.pointerType === "mouse") {
      window.addEventListener("mouseup", handlePointerUp, false);
    }
    if (event.pointerType === "touch") {
      window.addEventListener("touchend", handlePointerUp, false);
      window.addEventListener("touchmove", handleTouchMove, false);
    }
    if (
      !isPointerDown.current &&
      isInDestination.current &&
      modelRefs.current[model].position.y >= 0
    ) {
      isPointerDown.current = true;
      setScrollValue(scroll.current);
      setScrollValue((prev) => ({
        ...prev,
        scrollValue: scroll.current,
      }));
      const containerHeight = el.clientHeight;
      const totalHeight = el.scrollHeight;
      const scrollPercentage =
        (el.scrollTop / (totalHeight - containerHeight)) * 100;
      const scrollTopValue =
        (scrollPercentage / 100) * (totalHeight - containerHeight);
      setScrollValue((prev) => ({
        ...prev,
        scrollPercentage: scrollTopValue,
      }));
      pointerStartPosition.current.x = event.clientX;
    }
  };

  const geometry = new BoxGeometry(43.3, 1.3, 0);
  const material = new MeshBasicMaterial({
    color: "white",
    transparent: true,
    opacity: 0,
  });

  // Debounce function to limit event frequency
  function debounce(func, delay) {
    clearTimeout(debounceValue);
    debounceValue = setTimeout(func, delay);
  }

  return (
    <group onPointerMove={handlePointerMove}>
      <group
        onClick={() => {
          setRotateRight(true);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          // renderOrder={-100}
          transparent={true}
          path="assets/img/Arrow-Right.png"
          scale={arrowScale}
          position={packetrightArrowPosition}
        />
      </group>

      <group
        onClick={() => {
          setRotateLeft(true);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <PngLoader
          // renderOrder={-100}
          transparent={true}
          path="assets/img/Arrow-Left.png"
          scale={arrowScale}
          position={packetleftArrowPosition}
        />
      </group>
      <mesh geometry={geometry} material={material} position={[2, -4.2, 4]} />
      <group
        ref={groupRef}
        position={position}
        scale={scale}
        rotation={[0, packetRotation, 0]}
        onPointerDown={handlePointerDown}
      />
    </group>
  );
};

export default PacketCarousel3D;
