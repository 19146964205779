import React, { useEffect, useState } from "react";
import { InteractiveContent } from "../cameraMovement.component";
import PngLoader from "../pngLoader.component";
import DroppingSeed from "./droppingSeed.component";
import VaseModel from "./vaseModel.component";
import { useThree } from "@react-three/fiber";
import MovingTextComponent from "../movingText.component";

const Section2 = ({ page }) => {
  const [growPlant, setGrowPlant] = useState(false);
  const [direction, setDirection] = useState();
  const { width } = useThree((state) => state.viewport);
  const [seedPosition, setSeedPosition] = useState();
  const [vaseScale, setVaseScale] = useState();
  const [seedScale, setSeedScale] = useState();
  const [interactionValue, setInteractionValue] = useState();
  const [vasePosition, setVasePosition] = useState();
  const [isSeedInDestination, setIsSeedInDestination] = useState(false);
  useEffect(() => {
    if (width < 5.67) {
      setSeedPosition(width * 0.006);
      setVaseScale(width * 0.052);
      setSeedScale(width * 0.1);
      setInteractionValue([0, 0]);
      setVasePosition(-17);
    } else if (width < 7.7 && width >= 5.67) {
      setSeedPosition(width * 0.041);
      setVaseScale(width * 0.03);
      setSeedScale(width * 0.037);
      setInteractionValue([0, 0]);
      setVasePosition(-16.86);
    } else if (width >= 7.7 && width < 11.34) {
      setSeedPosition(width * 0.046);
      setVaseScale(width * 0.02);
      setSeedScale(width * 0.035);
      setInteractionValue([0.0008, 0.005]);
      setVasePosition(-16.86);
    } else if (width >= 11.34 && width <= 14.5) {
      setSeedPosition(width * 0.035);
      setVaseScale(width * 0.02);
      setSeedScale(width * 0.0325);
      setInteractionValue([0.008, 0.05]);
      setVasePosition(-16.86);
    } else {
      setSeedPosition(width * 0.026);
      setVaseScale(width * 0.02);
      setSeedScale(width * 0.0325);
      setInteractionValue([0.0008, 0.005]);
      setVasePosition(-16.86);
    }
  }, [width]);

  const droppingSeeds = [
    {
      position: [seedPosition, -14, 4],
      maxPositionY: -17,
      scale: [seedScale, seedScale, 0.3],
    },
  ];

  return (
    <>
      <PngLoader
        transparent={true}
        renderOrder={-10}
        path="assets/img/Section-2-Final-Image---Blue-01.png"
        position={[0, -2.24, 1.2]}
        zoom={0.7}
        scale={[4, 5.08, 1]}
      />

      <MovingTextComponent isSeedInDestination={isSeedInDestination} />

      {/* {interactionValue && (
        <InteractiveContent interactionValue={interactionValue}> */}
      {seedPosition && seedScale && (
        <>
          {droppingSeeds.map((seedData, index) => (
            <DroppingSeed
              key={index}
              position={seedData.position}
              maxPositionY={seedData.maxPositionY}
              page={page}
              animate={false}
              direction={direction}
              setDirection={setDirection}
              setGrowPlant={setGrowPlant}
              scale={seedData.scale}
              setIsSeedInDestination={setIsSeedInDestination}
            />
          ))}
        </>
      )}
      {seedPosition && vaseScale && (
        <VaseModel
          modelPath="/assets/3d-Models/PLANT4/Vase_FINAL3.gltf"
          scale={[vaseScale, vaseScale, vaseScale]}
          position={[seedPosition, vasePosition, 4]}
          rotating={false}
          page={page}
          animate={true}
          setDirection={setDirection}
          direction={direction}
          growPlant={growPlant}
          isSeedInDestination={isSeedInDestination}
        />
      )}
      {/* </InteractiveContent>
      )} */}
    </>
  );
};

export default Section2;
