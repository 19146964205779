import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { LoadingContext } from "../../context/loading.context";
import * as THREE from "three";
import { useScroll } from "../ScrollControls";
import { ScrollContext } from "../../context/scroll.context";
import { Section8Context } from "../../context/section8.context";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
const VaseModel = ({
  modelPath,
  setDirection,
  direction,
  scale,
  position,
  page,
  isSeedInDestination,
}) => {
  const { setLoading } = useContext(LoadingContext);
  // const [gltf, setGltf] = useState();
  const modelRef = useRef();
  const mixerRef = useRef(null);
  const { scroll, el } = useScroll();
  const [playForward, setPlayForward] = useState(null);
  const [isPlantGrown, setIsPlantGrown] = useState(false);
  const prevScrollRef = useRef(null);
  const { setEnableScroll, enableScroll } = useContext(ScrollContext);
  const { showContactPage } = useContext(Section8Context);
  const [isSection3, setIssection3] = useState(false);
  const [reversePlayForward, setReversePlayForward] = useState(false);

  const gltf = useLoader(GLTFLoader, modelPath);
  gltf.scene.scale.set(...scale);
  gltf.scene.renderOrder = 1;

  useEffect(() => {
    setLoading("section2", true);
  }, [gltf, setLoading]);

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y = 0.45 * Math.PI;
    }
    const currentValue = scroll.current * 10 * 10;
    if (mixerRef.current && currentValue > 4) {
      mixerRef.current.update(0.01);
    }

    // section2 entry
    if (currentValue > 42.5 && currentValue <= 54) {
      scroll.current += 0.14 / 100;
      if (currentValue < 54 && currentValue > 53.6)
        setScrollTopToPercentage(53.8, el);
    }

    // set direction to top
    if (currentValue < prevScrollRef.current && direction !== "top") {
      setDirection("top");
    }

    // set direction to down
    if (currentValue > prevScrollRef.current && direction !== "down") {
      setDirection("down");
    }

    // to set animation false while going bottom to top
    const isBetweenRange = currentValue >= 44 && currentValue <= 61;

    if (
      isBetweenRange &&
      prevScrollRef.current > currentValue &&
      !isSection3 &&
      playForward &&
      !isSeedInDestination
    ) {
      setIsPlantGrown(false);
      setPlayForward(false);
    }

    if (!playForward) {
      setIsPlantGrown(false);
    }

    if (
      currentValue > prevScrollRef.current &&
      prevScrollRef.current === 61.3 &&
      (!playForward || playForward === null) &&
      isSeedInDestination
    ) {
      if (!isSection3) setPlayForward(true);
      setTimeout(() => {
        setIsPlantGrown(true);
        setEnableScroll(true);
      }, 3000);
    }

    //section 3  to 2
    if (
      currentValue > 61.5 &&
      currentValue < 70 &&
      currentValue <= prevScrollRef.current
    ) {
      scroll.current -= 0.1 / 100;
      if (
        currentValue < 70 &&
        currentValue > 69.5 &&
        prevScrollRef.current - currentValue > 0.1
      ) {
        setScrollTopToPercentage(61.5, el);
      }
    }

    // stay in 2;
    if (currentValue.toFixed(1) == 61.5) {
      const containerHeight = el.clientHeight;
      const totalHeight = el.scrollHeight;
      const scrollTopValue = el.scrollTop;
      const scrollPercentage =
        (scrollTopValue / (totalHeight - containerHeight)) * 100;
      setIssection3(false);
      if (scrollPercentage < 61.2 || scrollPercentage > 61.5) {
        setScrollTopToPercentage(61.7, el);
      }
    }

    prevScrollRef.current = currentValue;

    //stay in 2 vase
    if (currentValue >= 59 && currentValue < 62) {
      prevScrollRef.current = 61.3;
      scroll.current = 61.5 / (page * 10);
    }

    // section 2 exit
    if (
      direction === "top" &&
      currentValue < 70 &&
      currentValue >= 54.5 &&
      // prevScrollRef.current - currentValue > 1 &&
      isSection3
    ) {
      scroll.current += 0.14 / 100;
      setScrollTopToPercentage(61.74, el);
    }

    // set true if section 3 started
    if (currentValue >= 69) {
      setIssection3(true);
    }

    // set false if not in section 3
    if (currentValue < 49) {
      setIssection3(false);
    }

    if (currentValue > 95 && !playForward) {
      setReversePlayForward(true);
      setPlayForward(true);
      setIsPlantGrown(true);
      setTimeout(() => {
        setReversePlayForward(false);
      }, 3000);
    }

    if (currentValue < 1 && playForward) {
      setPlayForward(false);
      setIsPlantGrown(false);
    }

    if (
      currentValue > 60 &&
      currentValue < 88 &&
      !isPlantGrown &&
      direction !== "top"
    ) {
      scroll.current = 0.615;
      prevScrollRef.current = 61.3;
      setScrollTopToPercentage(61.3, el);
      if (enableScroll && !isPlantGrown && !showContactPage) {
        setEnableScroll(false);
      }
    }
  });

  useEffect(() => {
    if (gltf && gltf.animations) {
      const animations = gltf.animations;
      if (animations && animations.length) {
        const mixer = new THREE.AnimationMixer(gltf.scene);
        animations.forEach((clip) => {
          const action = mixer.clipAction(clip);
          action.clampWhenFinished = true;
          action.repetitions = 1;
          if (playForward) {
            action.timeScale = 1;
            if (reversePlayForward) {
              action.timeScale = 2;
            }
          } else {
            action.timeScale = -1;
          }
          action.play();
        });
        mixerRef.current = mixer;
      }
    }
  }, [gltf, playForward]);

  return (
    <>
      {gltf && (
        <primitive ref={modelRef} object={gltf.scene} position={position} />
      )}
    </>
  );
};

export default VaseModel;
